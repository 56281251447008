import React, {useState} from 'react';
import {Field, ErrorMessage, useFormikContext} from 'formik';
import PdfComponent from "../../../common/Pdf";

export default function TasStep1({translation, i18n, setTasFormChoice}) {
    const [formStart, setFormStart] = useState(false);

    const formikProps = useFormikContext();

    const choices5 = [
        translation('choice5Label')
    ];

    const resetForm = event => {
        if (!formStart) {
            setFormStart(true);
            window.parent.postMessage({
                event: "form_start"
            }, "*");
        }
        const { target: {value, name } } = event;
        setTasFormChoice(value);
        formikProps.resetForm({values: ''});
        formikProps.setFieldValue(name, value);
    };

    return (
        <>
            <div className={'item'}>
                <h3>
                    {translation('mainTitle')}
                </h3>
            </div>

            <div className={'item'}>
                {translation("TAS_Generic_Form_Step1_Text_Welcome").split("\n").map((text, index) => <p key={index}>{text}</p>)}
            </div>

            <div className={'item'}>
                <h4>
                    {translation('step1Label')}
                </h4>
            </div>

            <div className={'item'}>
                <label  className={'form-label'}>
                    {translation('kindDamageLabel') + '*'}
                    <Field
                        name="tasFormChoice"
                        as='select'
                        className={'form-select'}
                        onChange={resetForm}>
                        <option value=''/>
                        <option value={translation('choice1Label')}>
                            {translation('choice1Label')}
                        </option>
                        <option value={translation('choice2Label')}>
                            {translation('choice2Label')}
                        </option>
                        <option value={translation('choice3Label')}>
                            {translation('choice3Label')}
                        </option>
                        <option value={translation('choice4Label')}>
                            {translation('choice4Label')}
                        </option>
                        <option value={translation('choice5Label')}>
                            {translation('choice5Label')}
                        </option>
                    </Field>
                </label>
                <ErrorMessage className={'text-danger'} name="tasFormChoice" component='div' />
            </div>

            <div className={'item'}>
                {choices5.includes(formikProps.values['tasFormChoice']) &&
                    <>
                        <p>
                            {translation('pdfFileText')}
                        </p>
                        <PdfComponent translation={translation} choice={formikProps.values['tasFormChoice']} language={i18n.language}/>
                        {i18n.language === "en" &&
                            <div className={'item'}>
                                <p>no English PDF available</p>
                            </div>
                        }
                    </>
                }
            </div>

        </>
    )
}