import React, {useEffect, useState} from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function PdfComponent({translation, choice, language}) {
    const [pdfFile, setPdfFile] = useState();
    const [pdfFileName, setPdfFileName] = useState('');

    useEffect(() => {
        const choices3 = [
            translation('choice3Label')
        ];

        const choices4 = [
            translation('choice4Label')
        ];

        const choices5 = [
            translation('choice5Label')
        ];

        if (choices3.includes(choice)) {
            switch (language) {
                case 'fr':
                    setPdfFile(require('../../Files/fr/modification-de-voyage-apres-le-depart.PDF'));
                    setPdfFileName('modification-de-voyage-apres-le-depart.PDF');
                    break;
                case 'de':
                    setPdfFile(require('../../Files/de/reiseaenderung-nach-der-abreise.PDF'));
                    setPdfFileName('reiseaenderung-nach-der-abreise.PDF');
                    break;
                case 'it':
                    setPdfFile(require('../../Files/it/dichiarazione-per-modifica-del-viaggio-dopo-la-partenza.pdf'));
                    setPdfFileName('dichiarazione-per-modifica-del-viaggio-dopo-la-partenza.pdf');
                    break;
                default:
                    setPdfFile(require('../../Files/de/reiseaenderung-nach-der-abreise.PDF'));
                    setPdfFileName('reiseaenderung-nach-der-abreise.PDF');
                    break;
            }
        }
        if (choices4.includes(choice)) {
            switch (language) {
                case 'fr':
                    setPdfFile(require('../../Files/fr/Déclaration de sinistre prise en charge de la franchise pour véhicules de location.pdf'));
                    setPdfFileName('Déclaration de sinistre prise en charge de la franchise pour véhicules de location.pdf');
                    break;
                case 'de':
                    setPdfFile(require('../../Files/de/Schadenmeldung für Übernahme des Selbstbehalts bei Mietfahrzeugen.pdf'));
                    setPdfFileName('Schadenmeldung für Übernahme des Selbstbehalts bei Mietfahrzeugen.pdf');
                    break;
                case 'it':
                    setPdfFile(require('../../Files/it/Notifica di sinistro per l’assunzione della franchigia di veicoli a noleggio.pdf'));
                    setPdfFileName('Notifica di sinistro per l’assunzione della franchigia di veicoli a noleggio.pdf');
                    break;
                default:
                    setPdfFile(require('../../Files/de/Schadenmeldung für Übernahme des Selbstbehalts bei Mietfahrzeugen.pdf'));
                    setPdfFileName('Schadenmeldung für Übernahme des Selbstbehalts bei Mietfahrzeugen.pdf');
                    break;
            }
        }
        if (choices5.includes(choice)) {
            switch (language) {
                case 'fr':
                    setPdfFile(require('../../Files/fr/declaration-de-sinistre-assurance-bagages.PDF'));
                    setPdfFileName('declaration-de-sinistre-assurance-bagages.PDF');
                    break;
                case 'de':
                    setPdfFile(require('../../Files/de/schadenmeldung-fuer-reisegepaeck.PDF'));
                    setPdfFileName('schadenmeldung-fuer-reisegepaeck.PDF');
                    break;
                case 'it':
                    setPdfFile(require('../../Files/it/notifica-di-sinistro-per-bagagli.PDF'));
                    setPdfFileName('notifica-di-sinistro-per-bagagli.PDF');
                    break;
                default:
                    setPdfFile(require('../../Files/de/schadenmeldung-fuer-reisegepaeck.PDF'));
                    setPdfFileName('schadenmeldung-fuer-reisegepaeck.PDF');
                    break;
            }
        }
    }, [setPdfFile, choice, language, translation]);

    return (
        <div className={'item'}>
            <a href={pdfFile} download={pdfFileName}>
                <PictureAsPdfIcon
                    sx={
                        {
                            fontSize: { xxs: 30, xs: 30, sm: 30, md: 30, lg: 30, xl: 30 },
                            color: 'var(--my-theme)'
                        }
                    }
                />
                {pdfFileName}
            </a>
        </div>
    )
}