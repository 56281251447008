import React from "react";
import {useFormikContext} from "formik";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import {PDFDownloadLink, Document, Page, Text, View, Font} from '@react-pdf/renderer'

export default function SummaryComponent({translation}) {
    const formikProps = useFormikContext();

    const printRefPart1 = React.useRef();
    const printRefPart2 = React.useRef();
    const printRefPart3 = React.useRef();
    const printRefPart4 = React.useRef();

    const choices1 = [
        translation('choice1Label')
    ];

    const choices2 = [
        translation('choice2Label')
    ];

    const choices3 = [
        translation('choice3Label')
    ];

    const choices4 = [
        translation('choice4Label')
    ];

    const getLabelGender = (gender) => {
        switch (gender) {
            case 'male':
                return translation('genderMaleLabel');
            case 'female':
                return translation('genderFemaleLabel');
            case 'other':
            default:
                return translation('genderOtherLabel');
        }
    }

    Font.register({
        family: 'Open Sans',
        fonts: [
            {src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf'},
            {src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600}
        ]
    })

    const MyDoc = () => (
        <Document>
            <Page size="A4" style={{backgroundColor: 'white', color: '#004173', fontFamily: 'Open Sans'}}>
                <View style={{color: '#004173', textAlign: 'center', margin: 10}}>
                    <Text>{translation('step1Label')}</Text>
                </View>
                <View style={{color: '#004173', textAlign: 'left', margin: 10}}>
                    <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('kindDamageLabel')}</Text>
                    <Text style={{fontSize: "10px"}}>{formikProps.values['tasFormChoice']}</Text>
                </View>
            </Page>
            <Page size="A4" style={{backgroundColor: 'white', color: '#004173', fontFamily: 'Open Sans'}}>
                <View style={{color: '#004173', textAlign: 'center', margin: 10}}>
                    <Text>{translation('step2Label')}</Text>
                </View>
                <View style={{color: '#004173', textAlign: 'left', margin: 10}}>
                    <View style={{margin: 5}}>
                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('insurerLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasInsurer']}</Text>
                    </View>

                    <View style={{margin: 5}}>
                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('policeNumberLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasPoliceNumber']}</Text>
                    </View>

                    <View style={{margin: 5}}>
                        <Text style={{fontSize: "10px"}}>{getLabelGender(formikProps.values['tasGender'])}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('firstnameLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasFirstname']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('surnameLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasSurname']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('addressLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasAddress']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('npaLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasAddressNPA']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('cityLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasAddressCity']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('landLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasLand']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('phoneNumberLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasPhone']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('phoneNumberBisLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasPhoneBis']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('emailLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasEmail']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "12px", fontWeight: "bold"}}>{translation('paymentDetailsLabel')}</Text>
                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('accountHolderLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasAccountHolder']}</Text>
                    </View>

                    <View style={{margin: 5}}><Text
                        style={{fontSize: "10px", fontWeight: "bold"}}>{translation('accountIbanLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasAccountIban']}</Text>
                    </View>

                    {!choices4.includes(formikProps.values['tasFormChoice']) &&
                        <>
                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('otherTravelerHaveToCancelLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler']}</Text>
                            </View>
                            {formikProps.values['tasOtherTraveler'] === translation('yesLabel') &&
                                <>
                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "12px", fontWeight: "bold"}}>{translation('travelerInfoLabel')}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('traveler1InfoLabel')}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('firstnameLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1Firstname']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('surnameLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1Surname']}</Text>
                                    </View>

                                    {choices3.includes(formikProps.values['tasFormChoice']) &&
                                        <>
                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('addressLabel')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1Address']}</Text>
                                            </View>

                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('npaLabel')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1NPA']}</Text>
                                            </View>

                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('cityLabel')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1City']}</Text>
                                            </View>

                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('birthdayLabel')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1Birthday']}</Text>
                                            </View>
                                        </>
                                    }

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('travelerSameETIProtection')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1SameETIProtection']}</Text>
                                    </View>

                                    {formikProps.values['tasOtherTraveler1SameETIProtection'] === translation('noLabel') &&
                                        <>
                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('travelerOwnETIProtection')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1OwnETIProtection']}</Text>
                                            </View>

                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('travelerTCSNumberLabel')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler1TCSNumber']}</Text>
                                            </View>
                                        </>
                                    }

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('otherTravelerHaveToCancelLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2']}</Text>
                                    </View>

                                    {formikProps.values['tasOtherTraveler2'] === translation('yesLabel') &&
                                        <>
                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('traveler2InfoLabel')}</Text>
                                            </View>

                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('firstnameLabel')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2Firstname']}</Text>
                                            </View>

                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('surnameLabel')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2Surname']}</Text>
                                            </View>

                                            {choices3.includes(formikProps.values['tasFormChoice']) &&
                                                <>
                                                    <View style={{margin: 5}}>
                                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('addressLabel')}</Text>
                                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2Address']}</Text>
                                                    </View>

                                                    <View style={{margin: 5}}>
                                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('npaLabel')}</Text>
                                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2NPA']}</Text>
                                                    </View>

                                                    <View style={{margin: 5}}>
                                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('cityLabel')}</Text>
                                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2City']}</Text>
                                                    </View>

                                                    <View style={{margin: 5}}>
                                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('birthdayLabel')}</Text>
                                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2Birthday']}</Text>
                                                    </View>
                                                </>
                                            }

                                            <View style={{margin: 5}}>
                                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('travelerSameETIProtection')}</Text>
                                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2SameETIProtection']}</Text>
                                            </View>

                                            {formikProps.values['tasOtherTraveler2SameETIProtection'] === translation('noLabel') &&
                                                <>
                                                    <View style={{margin: 5}}>
                                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('travelerOwnETIProtection')}</Text>
                                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2OwnETIProtection']}</Text>
                                                    </View>

                                                    <View style={{margin: 5}}>
                                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('travelerTCSNumberLabel')}</Text>
                                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTraveler2TCSNumber']}</Text>
                                                    </View>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </View>
            </Page>
            <Page size="A4" style={{backgroundColor: 'white', color: '#004173', fontFamily: 'Open Sans'}}>
                <View style={{color: '#004173', textAlign: 'center', margin: 10}}>
                    <Text>{translation('step3Label')}</Text>
                </View>
                <View style={{color: '#004173', textAlign: 'left', margin: 10}}>
                    {(choices3.includes(formikProps.values['tasFormChoice'])
                            || choices1.includes(formikProps.values['tasFormChoice'])
                            || choices4.includes(formikProps.values['tasFormChoice'])) &&
                        <>
                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('holidayDestinationLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasHolidayDestination']}</Text>
                            </View>
                        </>
                    }

                    {choices2.includes(formikProps.values['tasFormChoice']) &&
                        <>
                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('plannedEventLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasEventLocation']}</Text>
                            </View>
                        </>
                    }

                    <View style={{margin: 5}}>
                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('beginLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasBegin']}</Text>
                    </View>

                    <View style={{margin: 5}}>
                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('endLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasEnd']}</Text>
                    </View>

                    {!choices4.includes(formikProps.values['tasFormChoice']) &&
                        <View style={{margin: 5}}>
                            <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('postingLabel')}</Text>
                            <Text style={{fontSize: "10px"}}>{formikProps.values['tasPosting']}</Text>
                        </View>
                    }

                    <View style={{margin: 5}}>
                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('creditCardBookingLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasCreditCardBooking']}</Text>
                    </View>

                    {formikProps.values['tasCreditCardBooking'] === translation('yesLabel') &&
                        <View style={{margin: 5}}>
                            <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('creditCardTypeLabel')}</Text>
                            <Text style={{fontSize: "10px"}}>{formikProps.values['tasCreditCardType']}</Text>
                        </View>
                    }

                    <View style={{margin: 5}}>
                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('otherAssuranceLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherAssurance']}</Text>
                    </View>

                    {formikProps.values['tasOtherAssurance'] === translation('yesLabel') &&
                        <View style={{margin: 5}}>
                            <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('assuranceNameLabel')}</Text>
                            <Text style={{fontSize: "10px"}}>{formikProps.values['tasAssuranceName']}</Text>
                        </View>
                    }

                    {choices3.includes(formikProps.values['tasFormChoice']) &&
                        <>
                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('reportedOtherAssuranceLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasReportedOtherAssurance']}</Text>
                            </View>
                            {formikProps.values['tasReportedOtherAssurance'] === translation('yesLabel') &&
                                <View style={{margin: 5}}>
                                    <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('numberReportedOtherAssuranceLabel')}</Text>
                                    <Text style={{fontSize: "10px"}}>{formikProps.values['tasNumberReportedOtherAssurance']}</Text>
                                </View>
                            }
                        </>
                    }

                    {!choices4.includes(formikProps.values['tasFormChoice']) &&
                        <>
                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "12px", fontWeight: "bold"}}>{translation('damageInformationLabel')}</Text>
                            </View>

                            {!choices3.includes(formikProps.values['tasFormChoice']) &&
                                <View style={{margin: 5}}>
                                    <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('dateOfDamageLabel')}</Text>
                                    <Text style={{fontSize: "10px"}}>{formikProps.values['tasDateOfDamage']}</Text>
                                </View>
                            }

                            {choices3.includes(formikProps.values['tasFormChoice']) &&
                                <View style={{margin: 5}}>
                                    <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('suspensionDateLabel')}</Text>
                                    <Text style={{fontSize: "10px"}}>{formikProps.values['tasSuspensionDate']}</Text>
                                </View>
                            }

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('locationOfDamageLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasLocationOfDamage']}</Text>
                            </View>

                            {choices3.includes(formikProps.values['tasFormChoice']) &&
                                <>
                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('travelTypeLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasTravelTypeChoice']}</Text>
                                    </View>

                                    {formikProps.values['tasTravelTypeChoice'] === translation('travelType5Label') &&
                                        <View style={{margin: 5}}>
                                            <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('otherTravelTypeLabel')}</Text>
                                            <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherTravelType']}</Text>
                                        </View>
                                    }
                                </>
                            }

                            {choices1.includes(formikProps.values['tasFormChoice']) &&
                                <View style={{margin: 5}}>
                                    <Text style={{fontSize: "12px", fontWeight: "bold"}}>{translation('cantStartTripLabel')}</Text>
                                </View>
                            }

                            {choices2.includes(formikProps.values['tasFormChoice']) &&
                                <View style={{margin: 5}}>
                                    <Text style={{fontSize: "12px", fontWeight: "bold"}}>{translation('cantAttendEventLabel')}</Text>
                                </View>
                            }

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('damageReasonLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasReasonDamage']}</Text>
                            </View>

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('whatHappenedLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasWhatHappened']}</Text>
                            </View>

                            {!choices3.includes(formikProps.values['tasFormChoice']) &&
                                <>
                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('dateOfCancellationLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasDateOfCancellation']}</Text>
                                    </View>

                                    {choices1.includes(formikProps.values['tasFormChoice']) &&
                                        <View style={{margin: 5}}>
                                            <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('estimateDamageLabel')}</Text>
                                            <Text style={{fontSize: "10px"}}>{formikProps.values['tasEstimateDamage']}</Text>
                                        </View>
                                    }
                                </>
                            }
                        </>
                    }

                    {choices4.includes(formikProps.values['tasFormChoice']) &&
                        <>
                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('reportedOtherAssuranceLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasReportedOtherAssurance']}</Text>
                            </View>

                            {formikProps.values['tasReportedOtherAssurance'] === translation('yesLabel') &&
                                <View style={{margin: 5}}>
                                    <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('numberReportedOtherAssuranceLabel')}</Text>
                                    <Text style={{fontSize: "10px"}}>{formikProps.values['tasNumberReportedOtherAssurance']}</Text>
                                </View>
                            }

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('tenantFromLeaseLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasTenantFromLease']}</Text>
                            </View>

                            {formikProps.values['tasTenantFromLease'] === translation('otherPersonLabel') &&
                                <>
                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('firstnameLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasFirstnameOtherPerson']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('surnameLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasSurnameOtherPerson']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('birthdayLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasBirthdayOtherPerson']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('addressLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasAddressOtherPerson']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('npaLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasAddressNPAOtherPerson']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('cityLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasAddressCityOtherPerson']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('phoneNumberSimpleLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasPhoneOtherPerson']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('emailLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasEmailOtherPerson']}</Text>
                                    </View>

                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('beneficiarySameCoverageLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasBeneficiarySameCoverage']}</Text>
                                    </View>

                                    {formikProps.values['tasBeneficiarySameCoverage'] === translation('yesLabel') &&
                                        <View style={{margin: 5}}>
                                            <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('relationshipDegreeLabel')}</Text>
                                            <Text style={{fontSize: "10px"}}>{formikProps.values['tasRelationshipDegree']}</Text>
                                        </View>
                                    }
                                </>
                            }

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('nameAddressRentalCompanyLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasNameAddressRentalCompany']}</Text>
                            </View>

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('vehicleTypeLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasVehicleType']}</Text>
                            </View>

                            {formikProps.values['tasVehicleType'] === translation('otherVehicleTypeLabel') &&
                                <>
                                    <View style={{margin: 5}}>
                                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('otherVehicleTypeLabel')}</Text>
                                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherVehicleType']}</Text>
                                    </View>
                                </>
                            }

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('commentLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasComment']}</Text>
                            </View>

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('maxDeductibleAmountLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasMaxDeductibleAmount']}</Text>
                            </View>

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "12px", fontWeight: "bold"}}>{translation('damageInformationLabel')}</Text>
                            </View>

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('dateOfDamageLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasDateOfDamage']}</Text>
                            </View>

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('locationOfDamageLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasLocationOfDamage']}</Text>
                            </View>

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('totalDamageAmountDebitedLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasTotalDamageAmountDebited']}</Text>
                            </View>

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('isAboutLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasIsAbout']}</Text>
                            </View>

                            {formikProps.values['tasIsAbout'] === translation('otherSpecifyLabel') &&
                                <View style={{margin: 5}}>
                                    <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('otherSpecifyLabel')}</Text>
                                    <Text style={{fontSize: "10px"}}>{formikProps.values['tasOtherSpecify']}</Text>
                                </View>
                            }

                            <View style={{margin: 5}}>
                                <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('descriptionCourseDamageLabel')}</Text>
                                <Text style={{fontSize: "10px"}}>{formikProps.values['tasDescriptionCourseDamage']}</Text>
                            </View>
                        </>
                    }
                </View>
            </Page>
            <Page size="A4" style={{backgroundColor: 'white', color: '#004173', fontFamily: 'Open Sans'}}>
                <View style={{color: '#004173', textAlign: 'center', margin: 10}}>
                    <Text>{translation('step4Label')}</Text>
                </View>
                <View style={{color: '#004173', textAlign: 'left', margin: 10}}>
                    <View style={{margin: 5}}>
                        {formikProps.values['tasDocument'] !== '' &&
                            <>
                                {formikProps.values['tasDocument'].map((file, index) => (
                                    <Text style={{fontSize: "10px"}}>{file.name}</Text>
                                ))}
                            </>
                        }
                    </View>

                    <View style={{margin: 5}}>
                        <Text style={{fontSize: "10px", fontWeight: "bold"}}>{translation('tellAnythingElseLabel')}</Text>
                        <Text style={{fontSize: "10px"}}>{formikProps.values['tasTellAnythingElse']}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )

    return (
        <>
            <div className={'item'}>
                <h4>{translation('step5Label')}</h4>
            </div>

            <div ref={printRefPart1}>
                <div className={'item'}>
                    <h5>{translation('step1Label')}</h5>
                </div>

                <div className={'item'}>
                    <h6>{translation('kindDamageLabel')}</h6>
                    <p>{formikProps.values['tasFormChoice']}</p>
                </div>
            </div>
            <div ref={printRefPart2}>
                <div className={'item'}>
                    <h5>{translation('step2Label')}</h5>
                </div>

                <div className={'item'}>
                    <h6>{translation('insurerLabel')}</h6>
                    <p>{formikProps.values['tasInsurer']}</p>
                </div>

                <div className={'item'}>
                    <h6>{translation('policeNumberLabel')}</h6>
                    <p>{formikProps.values['tasPoliceNumber']}</p>
                </div>

                <div className={'item'}>
                    <div>
                        <p>{getLabelGender(formikProps.values['tasGender'])}</p>
                    </div>
                </div>

                <div className={'item-names'}>
                    <div>
                        <h6>{translation('firstnameLabel')}</h6>
                        <p>{formikProps.values['tasFirstname']}</p>
                    </div>
                    <div>
                        <h6>{translation('surnameLabel')}</h6>
                        <p>{formikProps.values['tasSurname']}</p>
                    </div>
                </div>

                <div className={'item'}>
                    <div>
                        <h6>{translation('addressLabel')}</h6>
                        <p>{formikProps.values['tasAddress']}</p>
                    </div>
                </div>

                <div className={'item-street'}>
                    <div>
                        <h6>{translation('npaLabel')}</h6>
                        <p>{formikProps.values['tasAddressNPA']}</p>
                    </div>
                    <div>
                        <h6>{translation('cityLabel')}</h6>
                        <p>{formikProps.values['tasAddressCity']}</p>
                    </div>
                </div>

                <div className={'item'}>
                    <h6>{translation('landLabel')}</h6>
                    <p>{formikProps.values['tasLand']}</p>
                </div>

                <div className={'item'}>
                    <h6>{translation('phoneNumberLabel')}</h6>
                    <p>{formikProps.values['tasPhone']}</p>
                </div>

                <div className={'item'}>
                    <h6>{translation('phoneNumberBisLabel')}</h6>
                    <p>{formikProps.values['tasPhoneBis']}</p>
                </div>

                <div className={'item'}>
                    <h6>{translation('emailLabel')}</h6>
                    <p>{formikProps.values['tasEmail']}</p>
                </div>

                <div className={'item'}>
                    <h5>
                        {translation('paymentDetailsLabel')}
                    </h5>
                </div>

                <div className={'item'}>
                    <h6>{translation('accountHolderLabel')}</h6>
                    <p>{formikProps.values['tasAccountHolder']}</p>
                </div>

                <div className={'item'}>
                    <h6>{translation('accountIbanLabel')}</h6>
                    <p>{formikProps.values['tasAccountIban']}</p>
                </div>

                {!choices4.includes(formikProps.values['tasFormChoice']) &&
                    <>
                        <div className={'item'}>
                            <h6>{translation('otherTravelerHaveToCancelLabel')}</h6>
                            <p>{formikProps.values['tasOtherTraveler']}</p>
                        </div>

                        {formikProps.values['tasOtherTraveler'] === translation('yesLabel') &&
                            <>
                                <div className={'item'}>
                                    <h5>{translation('travelerInfoLabel')}</h5>
                                </div>

                                <div className={'item'}>
                                    <h5>
                                        {translation('traveler1InfoLabel')}
                                    </h5>
                                </div>

                                <div className={'item-names'}>
                                    <div>
                                        <h6>{translation('firstnameLabel')}</h6>
                                        <p>{formikProps.values['tasOtherTraveler1Firstname']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('surnameLabel')}</h6>
                                        <p>{formikProps.values['tasOtherTraveler1Surname']}</p>
                                    </div>
                                </div>

                                {choices3.includes(formikProps.values['tasFormChoice']) &&
                                    <>
                                        <div className={'item'}>
                                            <h6>{translation('addressLabel')}</h6>
                                            <p>{formikProps.values['tasOtherTraveler1Address']}</p>
                                        </div>

                                        <div className={'item-street'}>
                                            <div>
                                                <h6>{translation('npaLabel')}</h6>
                                                <p>{formikProps.values['tasOtherTraveler1NPA']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation('cityLabel')}</h6>
                                                <p>{formikProps.values['tasOtherTraveler1City']}</p>
                                            </div>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('birthdayLabel')}</h6>
                                            <p>{formikProps.values['tasOtherTraveler1Birthday']}</p>
                                        </div>
                                    </>
                                }

                                <div className={'item'}>
                                    <h6>{translation('travelerSameETIProtection')}</h6>
                                    <p>{formikProps.values['tasOtherTraveler1SameETIProtection']}</p>
                                </div>

                                {formikProps.values['tasOtherTraveler1SameETIProtection'] === translation('noLabel') &&
                                    <>
                                        <div className={'item'}>
                                            <h6>{translation('travelerOwnETIProtection')}</h6>
                                            <p>{formikProps.values['tasOtherTraveler1OwnETIProtection']}</p>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('travelerTCSNumberLabel')}</h6>
                                            <p>{formikProps.values['tasOtherTraveler1TCSNumber']}</p>
                                        </div>
                                    </>
                                }

                                <div className={'item'}>
                                    <h6>{translation('otherTravelerHaveToCancelLabel')}</h6>
                                    <p>{formikProps.values['tasOtherTraveler2']}</p>
                                </div>

                                {formikProps.values['tasOtherTraveler2'] === translation('yesLabel') &&
                                    <>
                                        <div className={'item'}>
                                            <h5>
                                                {translation('traveler2InfoLabel')}
                                            </h5>
                                        </div>

                                        <div className={'item-names'}>
                                            <div>
                                                <h6>{translation('firstnameLabel')}</h6>
                                                <p>{formikProps.values['tasOtherTraveler2Firstname']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation('surnameLabel')}</h6>
                                                <p>{formikProps.values['tasOtherTraveler2Surname']}</p>
                                            </div>
                                        </div>

                                        {choices3.includes(formikProps.values['tasFormChoice']) &&
                                            <>
                                                <div className={'item'}>
                                                    <h6>{translation('addressLabel')}</h6>
                                                    <p>{formikProps.values['tasOtherTraveler2Address']}</p>
                                                </div>

                                                <div className={'item-street'}>
                                                    <div>
                                                        <h6>{translation('npaLabel')}</h6>
                                                        <p>{formikProps.values['tasOtherTraveler2NPA']}</p>
                                                    </div>
                                                    <div>
                                                        <h6>{translation('cityLabel')}</h6>
                                                        <p>{formikProps.values['tasOtherTraveler2City']}</p>
                                                    </div>
                                                </div>

                                                <div className={'item'}>
                                                    <h6>{translation('birthdayLabel')}</h6>
                                                    <p>{formikProps.values['tasOtherTraveler2Birthday']}</p>
                                                </div>
                                            </>
                                        }

                                        <div className={'item'}>
                                            <h6>{translation('travelerSameETIProtection')}</h6>
                                            <p>{formikProps.values['tasOtherTraveler2SameETIProtection']}</p>
                                        </div>

                                        {formikProps.values['tasOtherTraveler2SameETIProtection'] === translation('noLabel') &&
                                            <>
                                                <div className={'item'}>
                                                    <h6>{translation('travelerOwnETIProtection')}</h6>
                                                    <p>{formikProps.values['tasOtherTraveler2OwnETIProtection']}</p>
                                                </div>

                                                <div className={'item'}>
                                                    <h6>{translation('travelerTCSNumberLabel')}</h6>
                                                    <p>{formikProps.values['tasOtherTraveler2TCSNumber']}</p>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </div>

            <div ref={printRefPart3}>
                <div className={'item'}>
                    <h5>{translation('step3Label')}</h5>
                </div>

                {(choices3.includes(formikProps.values['tasFormChoice'])
                        || choices1.includes(formikProps.values['tasFormChoice'])
                        || choices4.includes(formikProps.values['tasFormChoice'])) &&
                    <>
                        <div className={'item'}>
                            <h6>{translation('holidayDestinationLabel')}</h6>
                            <p>{formikProps.values['tasHolidayDestination']}</p>
                        </div>
                    </>
                }

                {choices2.includes(formikProps.values['tasFormChoice']) &&
                    <>
                        <div className={'item'}>
                            <h6>{translation('plannedEventLabel')}</h6>
                            <p>{formikProps.values['tasEventLocation']}</p>
                        </div>
                    </>
                }

                <div className={'item'}>
                    <h6>{translation('beginLabel')}</h6>
                    <p>{formikProps.values['tasBegin']}</p>
                </div>

                <div className={'item'}>
                    <h6>{translation('endLabel')}</h6>
                    <p>{formikProps.values['tasEnd']}</p>
                </div>

                {!choices4.includes(formikProps.values['tasFormChoice']) &&
                    <div className={'item'}>
                        <h6>{translation('postingLabel')}</h6>
                        <p>{formikProps.values['tasPosting']}</p>
                    </div>
                }

                <div className={'item'}>
                    <h6>{translation('creditCardBookingLabel')}</h6>
                    <p>{formikProps.values['tasCreditCardBooking']}</p>
                </div>

                {formikProps.values['tasCreditCardBooking'] === translation('yesLabel') &&
                    <div className={'item'}>
                        <h6>{translation('creditCardTypeLabel')}</h6>
                        <p>{formikProps.values['tasCreditCardType']}</p>
                    </div>
                }

                <div className={'item'}>
                    <h6>{translation('otherAssuranceLabel')}</h6>
                    <p>{formikProps.values['tasOtherAssurance']}</p>
                </div>

                {formikProps.values['tasOtherAssurance'] === translation('yesLabel') &&
                    <div className={'item'}>
                        <h6>{translation('assuranceNameLabel')}</h6>
                        <p>{formikProps.values['tasAssuranceName']}</p>
                    </div>
                }

                {choices3.includes(formikProps.values['tasFormChoice']) &&
                    <>
                        <div className={'item'}>
                            <h6>{translation('reportedOtherAssuranceLabel')}</h6>
                            <p>{formikProps.values['tasReportedOtherAssurance']}</p>
                        </div>
                        {formikProps.values['tasReportedOtherAssurance'] === translation('yesLabel') &&
                            <div className={'item'}>
                                <h6>{translation('numberReportedOtherAssuranceLabel')}</h6>
                                <p>{formikProps.values['tasNumberReportedOtherAssurance']}</p>
                            </div>
                        }
                    </>
                }

                {!choices4.includes(formikProps.values['tasFormChoice']) &&
                    <>
                        <div className={'item'}>
                            <h5>{translation('damageInformationLabel')}</h5>
                        </div>

                        {!choices3.includes(formikProps.values['tasFormChoice']) &&
                            <div className={'item'}>
                                <h6>{translation('dateOfDamageLabel')}</h6>
                                <p>{formikProps.values['tasDateOfDamage']}</p>
                            </div>
                        }

                        {choices3.includes(formikProps.values['tasFormChoice']) &&
                            <div className={'item'}>
                                <h6>{translation('suspensionDateLabel')}</h6>
                                <p>{formikProps.values['tasSuspensionDate']}</p>
                            </div>
                        }

                        <div className={'item'}>
                            <h6>{translation('locationOfDamageLabel')}</h6>
                            <p>{formikProps.values['tasLocationOfDamage']}</p>
                        </div>

                        {choices3.includes(formikProps.values['tasFormChoice']) &&
                            <>
                                <div className={'item'}>
                                    <h6>{translation('travelTypeLabel')}</h6>
                                    <p>{formikProps.values['tasTravelTypeChoice']}</p>
                                </div>

                                {formikProps.values['tasTravelTypeChoice'] === translation('travelType5Label') &&
                                    <div className={'item'}>
                                        <h6>{translation('otherTravelTypeLabel')}</h6>
                                        <p>{formikProps.values['tasOtherTravelType']}</p>
                                    </div>
                                }
                            </>
                        }

                        {choices1.includes(formikProps.values['tasFormChoice']) &&
                            <div className={'item'}>
                                <h6>{translation('cantStartTripLabel')}</h6>
                            </div>
                        }

                        {choices2.includes(formikProps.values['tasFormChoice']) &&
                            <div className={'item'}>
                                <h6>{translation('cantAttendEventLabel')}</h6>
                            </div>
                        }

                        <div className={'item'}>
                            <h6>{translation('damageReasonLabel')}</h6>
                            <p>{formikProps.values['tasReasonDamage']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('whatHappenedLabel')}</h6>
                            <p>{formikProps.values['tasWhatHappened']}</p>
                        </div>

                        {!choices3.includes(formikProps.values['tasFormChoice']) &&
                            <>
                                <div className={'item'}>
                                    <h6>{translation('dateOfCancellationLabel')}</h6>
                                    <p>{formikProps.values['tasDateOfCancellation']}</p>
                                </div>

                                {choices1.includes(formikProps.values['tasFormChoice']) &&
                                    <div className={'item'}>
                                        <h6>{translation('estimateDamageLabel')}</h6>
                                        <p>{formikProps.values['tasEstimateDamage']}</p>
                                    </div>
                                }
                            </>
                        }
                    </>
                }

                {choices4.includes(formikProps.values['tasFormChoice']) &&
                    <>
                        <div className={'item'}>
                            <h6>{translation('reportedOtherAssuranceLabel')}</h6>
                            <p>{formikProps.values['tasReportedOtherAssurance']}</p>
                        </div>

                        {formikProps.values['tasReportedOtherAssurance'] === translation('yesLabel') &&
                            <div className={'item'}>
                                <div>
                                    <h6>{translation('numberReportedOtherAssuranceLabel')}</h6>
                                    <p>{formikProps.values['tasNumberReportedOtherAssurance']}</p>
                                </div>
                            </div>
                        }

                        <div className={'item'}>
                            <h6>{translation('tenantFromLeaseLabel')}</h6>
                            <p>{formikProps.values['tasTenantFromLease']}</p>
                        </div>
                        {formikProps.values['tasTenantFromLease'] === translation('otherPersonLabel') &&
                            <>

                                <div className={'item-names'}>
                                    <div>
                                        <h6>{translation('firstnameLabel')}</h6>
                                        <p>{formikProps.values['tasFirstnameOtherPerson']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('surnameLabel')}</h6>
                                        <p>{formikProps.values['tasSurnameOtherPerson']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <div>
                                        <h6>{translation('birthdayLabel')}</h6>
                                        <p>{formikProps.values['tasBirthdayOtherPerson']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <div>
                                        <h6>{translation('addressLabel')}</h6>
                                        <p>{formikProps.values['tasAddressOtherPerson']}</p>
                                    </div>
                                </div>

                                <div className={'item-street'}>
                                    <div>
                                        <h6>{translation('npaLabel')}</h6>
                                        <p>{formikProps.values['tasAddressNPAOtherPerson']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('cityLabel')}</h6>
                                        <p>{formikProps.values['tasAddressCityOtherPerson']}</p>
                                    </div>
                                </div>

                                <div className={'item-names'}>
                                    <div>
                                        <h6>{translation('phoneNumberSimpleLabel')}</h6>
                                        <p>{formikProps.values['tasPhoneOtherPerson']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('emailLabel')}</h6>
                                        <p>{formikProps.values['tasEmailOtherPerson']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('beneficiarySameCoverageLabel')}</h6>
                                    <p>{formikProps.values['tasBeneficiarySameCoverage']}</p>
                                </div>

                                {formikProps.values['tasBeneficiarySameCoverage'] === translation('yesLabel') &&
                                    <div className={'item'}>
                                        <h6>{translation('relationshipDegreeLabel')}</h6>
                                        <p>{formikProps.values['tasRelationshipDegree']}</p>
                                    </div>
                                }
                            </>
                        }

                        <div className={'item'}>
                            <h6>{translation('nameAddressRentalCompanyLabel')}</h6>
                            <p>{formikProps.values['tasNameAddressRentalCompany']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('vehicleTypeLabel')}</h6>
                            <p>{formikProps.values['tasVehicleType']}</p>
                        </div>

                        {formikProps.values['tasVehicleType'] === translation('otherVehicleTypeLabel') &&
                            <>
                                <div className={'item'}>
                                    <h6>{translation('otherVehicleTypeLabel')}</h6>
                                    <p>{formikProps.values['tasOtherVehicleType']}</p>
                                </div>
                            </>
                        }

                        <div className={'item'}>
                            <h6>{translation('commentLabel')}</h6>
                            <p>{formikProps.values['tasComment']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('maxDeductibleAmountLabel')}</h6>
                            <p>{formikProps.values['tasMaxDeductibleAmount']}</p>
                        </div>

                        <div className={'item'}>
                            <h5>{translation('damageInformationLabel')}</h5>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('dateOfDamageLabel')}</h6>
                            <p>{formikProps.values['tasDateOfDamage']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('locationOfDamageLabel')}</h6>
                            <p>{formikProps.values['tasLocationOfDamage']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('totalDamageAmountDebitedLabel')}</h6>
                            <p>{formikProps.values['tasTotalDamageAmountDebited']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('isAboutLabel')}</h6>
                            <p>{formikProps.values['tasIsAbout']}</p>
                        </div>

                        {formikProps.values['tasIsAbout'] === translation('otherSpecifyLabel') &&
                            <div className={'item'}>
                                <h6>{translation('otherSpecifyLabel')}</h6>
                                <p>{formikProps.values['tasOtherSpecify']}</p>
                            </div>
                        }

                        <div className={'item'}>
                            <h6>{translation('descriptionCourseDamageLabel')}</h6>
                            <p>{formikProps.values['tasDescriptionCourseDamage']}</p>
                        </div>
                    </>
                }
            </div>

            <div ref={printRefPart4}>
                <div className={'item'}>
                    <h5>{translation('step4Label')}</h5>
                </div>

                <div className={'item'}>
                    {formikProps.values['tasDocument'] !== '' &&
                        <>
                            {formikProps.values['tasDocument'].map((file, index) => (
                                <p key={index}>{file.name}</p>
                            ))}
                        </>
                    }
                </div>

                <div className={'item'}>
                    <h6>{translation('tellAnythingElseLabel')}</h6>
                    <p>{formikProps.values['tasTellAnythingElse']}</p>
                </div>
            </div>

            <div>
                <PDFDownloadLink className={'button'} document={<MyDoc/>} fileName="tas.pdf">
                    {({blob, url, loading, error}) => (loading ? 'Loading document...' : 'Download')}
                </PDFDownloadLink>
            </div>
        </>
    )
}