import React, {useEffect} from "react";
import {useFormikContext} from "formik";

export default function ConfirmationComponent({status, translation, i18n}) {
    const confirmation = (status === 200 || status === 202);
    const normalizedLanguage = i18n.language.split('-')[0] ?? "de";

    const formikProps = useFormikContext();

    const choices3 = [
        translation('choice3Label')
    ];

    const videosId = {
        fr: "0rgq1sMnPvw",
        de: "afqSSK14Yrs",
        it: "fUNQr6nLNwQ",
        en: "afqSSK14Yrs"
    }

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'instant'});
            if ('parentIFrame' in window) {
                window.parentIFrame.scrollTo(0, 0);
            }
        }, 0);
    });

    return (
        <>
            {(() => {
                if (confirmation) {
                    return (
                        <>
                            <div className={'item-message'}>
                                <h6>
                                    {translation('successMessage')}
                                </h6>
                            </div>

                            <div className={'item-message'}>
                                <p>{translation('successConfirmationEmail')}</p>
                            </div>

                            <div className={'item-message'}>
                                <p>{translation("TAS_Generic_Form_Confirmation_Text_Disclaimer")}</p>
                            </div>

                            <div className={'item-message'}>
                                <p>{translation('videoText')}</p>
                            </div>

                            {!choices3.includes(formikProps.values['tasFormChoice']) &&
                            <div className={'item-message'}>
                                <iframe
                                    className="video"
                                    src={"https://www.youtube.com/embed/" + videosId[normalizedLanguage] + "?hl=" + normalizedLanguage}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen>
                                </iframe>
                            </div>
                            }

                            <div className={'item-message'}>
                                <a target="_parent" href={"https://tas-versicherungen.ch/" + i18n.language ?? "de"}>{translation('closeWindowsLabel')}</a>
                            </div>

                            <hr/>

                            <div className={'item-message'}>
                                <p>{translation('confirmationTasSa')}</p>
                                <p>{translation('confirmationTel')}</p>
                                <p>{translation('confirmationEmail')}</p>
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className={'item-message'}>
                                <h6>
                                    {translation('errorMessage')}
                                </h6>
                            </div>

                            <div className={'item-message'}>
                                <a target="_parent" href={"https://tas-versicherungen.ch/" + i18n.language ?? "de" + "/kontakt"}>{translation('contactSupportLabel')}</a>
                            </div>

                            <hr/>

                            <div className={'item-message'}>
                                <p>{translation('confirmationTasSa')}</p>
                                <p>{translation('confirmationTel')}</p>
                                <p>{translation('confirmationEmail')}</p>
                            </div>
                        </>
                    )
                }
            })()}
        </>
    )
}