export const extensions = [".jpg", ".jpeg", ".gif", ".png", ".pdf", ".doc", ".docx", ".gif", ".zip", ".xls", ".xlsx"];

export function getExtension(value) {
    switch (value) {
        case 'image/jpg':
            return '.jpg'
        case 'image/jpeg':
            return '.jpeg'
        case 'image/gif':
            return '.gif'
        case 'image/png':
            return '.png'
        case 'application/pdf':
            return '.pdf'
        case 'video/mpeg':
            return '.mpeg'
        case 'application/x-tar':
            return '.tar'
        case 'application/zip':
            return '.zip'
        case 'application/x-7z-compressed':
            return '.7z'
        case 'audio/mpeg':
            return '.mp3'
        case 'application/msword':
            return '.doc'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return '.docx'
        case 'application/vnd.ms-excel':
            return '.xls'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return '.xlsx'
        default:
            return ''
    }
}