import React from 'react';
import {useFormikContext} from 'formik';
import TravelCancellationBeforeDeparture from "./TravelCancellationBeforeDeparture";
import EventTicketCancellation from "./EventTicketCancellation";
import ChangeTravelAfterDeparture from "./ChangeTravelAfterDeparture";
import AssumptionDeductibleRentalVehicles from "./AssumptionDeductibleRentalVehicles";

export default function TasStep3({translation}) {
    const formikProps = useFormikContext();

    return (
        <>
            <div className={'item'}>
                <h4>
                    {translation('step3Label')}
                </h4>
            </div>

            {translation('choice1Label') === formikProps.values['tasFormChoice'] &&
                <TravelCancellationBeforeDeparture translation={translation} />
            }

            {translation('choice2Label') === formikProps.values['tasFormChoice'] &&
                <EventTicketCancellation translation={translation} />
            }

            {translation('choice3Label') === formikProps.values['tasFormChoice'] &&
                <ChangeTravelAfterDeparture translation={translation} />
            }

            {translation('choice4Label') === formikProps.values['tasFormChoice'] &&
                <AssumptionDeductibleRentalVehicles translation={translation} />
            }
        </>
    )
}