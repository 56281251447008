import React from "react";
import {Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WarningIcon from "@mui/icons-material/Warning";

export default function StepperComponent({steps, activeStep}) {
    const CustomConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`] : {
            [theme.breakpoints.down('sm')] : {
                top: 10,
                left: 'calc(-50% + 12px)',
                right: 'calc(50% + 12px)'
            }
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: 'var(--my-theme-hover)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: 'var(--my-theme-hover)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
            [theme.breakpoints.down('sm')] : {
                borderTopWidth: 2
            }
        }
    }));

    const iconStepper = (index) => {
        switch (index) {
            case 0:
                return <SettingsIcon
                    sx={{ fontSize: { xxs: 20, xs: 20, sm: 24, md: 24, lg: 24, xl: 24 } }}
                    color={index > activeStep ? "disabled" : ""}
                />;
            case 1:
                return <PersonRoundedIcon
                    sx={{ fontSize: { xxs: 20, xs: 20, sm: 24, md: 24, lg: 24, xl: 24 } }}
                    color={index > activeStep ? "disabled" : ""}
                />;
            case 2:
                return <WarningIcon
                    sx={{ fontSize: { xxs: 20, xs: 20, sm: 24, md: 24, lg: 24, xl: 24 } }}
                    color={index > activeStep ? "disabled" : ""}
                />;
            case 3:
                return <AttachmentIcon
                    sx={{ fontSize: { xxs: 20, xs: 20, sm: 24, md: 24, lg: 24, xl: 24 } }}
                    color={index > activeStep ? "disabled" : ""}
                />;
            case 4:
                return <AssignmentIcon
                    sx={{ fontSize: { xxs: 20, xs: 20, sm: 24, md: 24, lg: 24, xl: 24 } }}
                    color={index > activeStep ? "disabled" : ""}
                />;
            default:
                return <SettingsIcon
                    sx={{ fontSize: { xxs: 20, xs: 20, sm: 24, md: 24, lg: 24, xl: 24 } }}
                    color={index > activeStep ? "disabled" : ""}
                />;
        }
    }

    return (
        <Stepper activeStep={activeStep} connector={<CustomConnector />} alternativeLabel>
            {steps.map((step, index) => (
                <Step
                    key={index}
                    sx={{
                        '& .MuiStepLabel-root .Mui-completed': {
                            color: 'var(--my-theme)',
                        },
                        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                            color: 'var(--my-theme)',
                        },
                        '& .MuiStepLabel-root .Mui-active': {
                            color: 'var(--my-theme)',
                        },
                        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                            color: 'var(--my-theme)',
                        },
                    }}>
                    <StepLabel icon={iconStepper(index)}>{step}</StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}