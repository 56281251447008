import * as Yup from 'yup';
import {getExtension, extensions} from "../../common/functions";
import bytes from "bytes";
import 'yup-phone';

import I18n from '../../../i18n';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const ibantools = require('ibantools');

const choices1 = [
    I18n.t('choice1Label')
];

const travelTypeChoices = [
    I18n.t('travelType5Label')
];

const choices3 = [
    I18n.t('choice3Label')
];

const choices4 = [
    I18n.t('choice4Label')
];

export default [
    Yup.object().shape({
        ['tasFormChoice']: Yup.string().required(I18n.t('requiredDefaultMessage'))
    }),
    Yup.object().shape({
        ['tasInsurer']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['tasPoliceNumber']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['tasGender']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['tasFirstname']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['tasSurname']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['tasBirthday']: Yup.date(),
        ['tasAddress']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['tasAddressNPA']: Yup.string()
            .required(I18n.t('requiredDefaultMessage'))
            .matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')),
        ['tasAddressCity']: Yup.string()
            .required(I18n.t('requiredDefaultMessage')),
        ['tasLand']: Yup.string()
            .required(I18n.t('requiredDefaultMessage')),
        ['tasPhone']: Yup.string()
            .required(I18n.t('requiredDefaultMessage'))
            .test(
                'phone',
                I18n.t('validateDefaultMessage'),
                async (value) => {
                    try {
                        if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'CH'), 'CH')
                            || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'FR'), 'FR')
                            || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'DE'), 'DE')
                            || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'IT'), 'IT')
                        ) {
                            return true;
                        }
                    } catch (error) {
                        return false;
                    }
                }
            ),
        ['tasPhoneBis']: Yup.mixed()
            .test(
                'phoneBis',
                I18n.t('validateDefaultMessage'),
                async (value) => {
                    if (value) {
                        try {
                            if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'CH'), 'CH')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'FR'), 'FR')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'DE'), 'DE')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'IT'), 'IT')
                            ) {
                                return true;
                            }
                        } catch (error) {
                            return false;
                        }
                    } else {
                        return true;
                    }
                }
            ),
        ['tasEmail']: Yup.string()
            .required(I18n.t('requiredDefaultMessage'))
            .email(I18n.t('validateDefaultMessage')),
        ['tasAccountHolder']: Yup.string(),
        ['tasAccountIban']: Yup.string()
            .test(
                'ibanFormat',
                I18n.t('validateDefaultMessage'),
                value => {
                    if (value) {
                        return ibantools.isValidIBAN(ibantools.electronicFormatIBAN(value));
                    } else {
                        return true;
                    }
                }
            ),
        ['tasOtherTraveler']: Yup.string(),
        ['tasOtherTraveler1Firstname']: Yup.string()
            .when('tasOtherTraveler', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['tasOtherTraveler1Surname']: Yup.string()
            .when('tasOtherTraveler', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['tasOtherTraveler1Address']: Yup.string(),
        ['tasOtherTraveler1NPA']: Yup.string().matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')),
        ['tasOtherTraveler1City']: Yup.string(),
        ['tasOtherTraveler1Birthday']: Yup.date(),
        ['tasOtherTraveler1SameETIProtection']: Yup.string()
            .when('tasOtherTraveler', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['tasOtherTraveler1OwnETIProtection']: Yup.string(),
        ['tasOtherTraveler1TCSNumber']: Yup.string(),
        ['tasOtherTraveler2Firstname']: Yup.string(),
        ['tasOtherTraveler2Surname']: Yup.string(),
        ['tasOtherTraveler2Address']: Yup.string(),
        ['tasOtherTraveler2NPA']: Yup.string().matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')),
        ['tasOtherTraveler2City']: Yup.string(),
        ['tasOtherTraveler2Birthday']: Yup.date(),
        ['tasOtherTraveler2SameETIProtection']: Yup.string(),
        ['tasOtherTraveler2OwnETIProtection']: Yup.string(),
        ['tasOtherTraveler2TCSNumber']: Yup.string()
    }),
    Yup.object().shape({
        ['saveChoice']: Yup.string(),
        ['tasBegin']: Yup.date().required(I18n.t('requiredDefaultMessage')),
        ['tasEnd']: Yup.date().required(I18n.t('requiredDefaultMessage')),
        ['tasPosting']: Yup.date()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (!choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasCreditCardBooking']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['tasCreditCardType']: Yup.string(),
        ['tasOtherAssurance']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['tasAssuranceName']: Yup.string()
            .when('tasOtherAssurance', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['tasReportedOtherAssurance']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices3.includes(tasFormChoice) || choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasNumberReportedOtherAssurance']: Yup.string()
            .when('tasReportedOtherAssurance', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['tasDateOfDamage']: Yup.date()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (!choices3.includes(tasFormChoice)) {
                    return Yup.date().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasSuspensionDate']: Yup.date()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices3.includes(tasFormChoice)) {
                    return Yup.date().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasLocationOfDamage']: Yup.string(),
        ['tasTravelTypeChoice']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices3.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasOtherTravelType']: Yup.string()
            .when(['tasTravelTypeChoice'], (tasTravelTypeChoice, schema) => {
                if (travelTypeChoices.includes(tasTravelTypeChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasReasonDamage']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (!choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasWhatHappened']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (!choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasDateOfCancellation']: Yup.date(),
        ['tasHolidayDestination']: Yup.string(),
        ['tasEventLocation']: Yup.string(),
        ['tasEstimateDamage']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices1.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasResponsibleAccident']: Yup.string()
            .when(['tasTravelTypeChoice', 'tasReasonDamage'], (tasFormChoice, tasReasonDamage, schema) => {
                if (choices3.includes(tasFormChoice) && tasReasonDamage === I18n.t('reason1DamageLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasResponsibleAccidentFirstname']: Yup.string()
            .when(['tasFormChoice', 'tasReasonDamage', 'tasResponsibleAccident'], (tasFormChoice, tasReasonDamage, tasResponsibleAccident, schema) => {
                if (choices3.includes(tasFormChoice)
                    && tasReasonDamage === I18n.t('reason1DamageLabel')
                    && tasResponsibleAccident === I18n.t('reason4DamageLabel')){
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasResponsibleAccidentSurname']: Yup.string()
            .when(['tasFormChoice', 'tasReasonDamage', 'tasResponsibleAccident'], (tasFormChoice, tasReasonDamage, tasResponsibleAccident, schema) => {
                if (choices3.includes(tasFormChoice)
                    && tasReasonDamage === I18n.t('reason1DamageLabel')
                    && tasResponsibleAccident === I18n.t('reason4DamageLabel')){
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasNameLiabilityInsurance']: Yup.string()
            .when(['tasFormChoice', 'tasReasonDamage', 'tasResponsibleAccident'], (tasFormChoice, tasReasonDamage, tasResponsibleAccident, schema) => {
                if (choices3.includes(tasFormChoice)
                    && tasReasonDamage === I18n.t('reason1DamageLabel')
                    && tasResponsibleAccident === I18n.t('reason4DamageLabel')){
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasPolicyNumber']: Yup.string()
            .when(['tasFormChoice', 'tasReasonDamage', 'tasResponsibleAccident'], (tasFormChoice, tasReasonDamage, tasResponsibleAccident, schema) => {
                if (choices3.includes(tasFormChoice)
                    && tasReasonDamage === I18n.t('reason1DamageLabel')
                    && tasResponsibleAccident === I18n.t('reason4DamageLabel')
                ) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasUnderInfluence']: Yup.string()
            .when(['tasFormChoice', 'tasReasonDamage', 'tasResponsibleAccident'], (tasFormChoice, tasReasonDamage, tasResponsibleAccident, schema) => {
                if (choices3.includes(tasFormChoice)
                    && tasReasonDamage === I18n.t('reason1DamageLabel')
                    && tasResponsibleAccident === I18n.t('youLabel')
                ) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasPoliceReport']: Yup.string()
            .when(['tasFormChoice', 'tasReasonDamage', 'tasResponsibleAccident'], (tasFormChoice, tasReasonDamage, tasResponsibleAccident, schema) => {
                if (choices3.includes(tasFormChoice)
                    && tasReasonDamage === I18n.t('reason1DamageLabel')
                    && tasResponsibleAccident === I18n.t('youLabel')
                ) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasTenantFromLease']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasGenderOtherPerson']: Yup.string()
            .when(['tasFormChoice', 'tasTenantFromLease'], (tasFormChoice, tasTenantFromLease, schema) => {
                if (choices4.includes(tasFormChoice) && tasTenantFromLease === I18n.t('otherPersonLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasFirstnameOtherPerson']: Yup.string()
            .when(['tasFormChoice', 'tasTenantFromLease'], (tasFormChoice, tasTenantFromLease, schema) => {
                if (choices4.includes(tasFormChoice) && tasTenantFromLease === I18n.t('otherPersonLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasSurnameOtherPerson']: Yup.string()
            .when(['tasFormChoice', 'tasTenantFromLease'], (tasFormChoice, tasTenantFromLease, schema) => {
                if (choices4.includes(tasFormChoice) && tasTenantFromLease === I18n.t('otherPersonLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasBirthdayOtherPerson']: Yup.string(),
        ['tasAddressOtherPerson']: Yup.string()
            .when(['tasFormChoice', 'tasTenantFromLease'], (tasFormChoice, tasTenantFromLease, schema) => {
                if (choices4.includes(tasFormChoice) && tasTenantFromLease === I18n.t('otherPersonLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasAddressNPAOtherPerson']: Yup.string()
            .when(['tasFormChoice', 'tasTenantFromLease'], (tasFormChoice, tasTenantFromLease, schema) => {
                if (choices4.includes(tasFormChoice) && tasTenantFromLease === I18n.t('otherPersonLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasAddressCityOtherPerson']: Yup.string()
            .when(['tasFormChoice', 'tasTenantFromLease'], (tasFormChoice, tasTenantFromLease, schema) => {
                if (choices4.includes(tasFormChoice) && tasTenantFromLease === I18n.t('otherPersonLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasPhoneOtherPerson']: Yup.string(),
        ['tasEmailOtherPerson']: Yup.string(),
        ['tasBeneficiarySameCoverage']: Yup.string()
            .when(['tasFormChoice', 'tasTenantFromLease'], (tasFormChoice, tasTenantFromLease, schema) => {
                if (choices4.includes(tasFormChoice) && tasTenantFromLease === I18n.t('otherPersonLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasRelationshipDegree']: Yup.string()
            .when(['tasFormChoice', 'tasBeneficiarySameCoverage'], (tasFormChoice, tasBeneficiarySameCoverage, schema) => {
                if (choices4.includes(tasFormChoice) && tasBeneficiarySameCoverage === I18n.t('yesLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasVehicleType']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasOtherVehicleType']: Yup.string()
            .when(['tasFormChoice', 'tasVehicleType'], (tasFormChoice, tasVehicleType, schema) => {
                if (choices4.includes(tasFormChoice) && tasVehicleType === I18n.t('otherVehicleTypeLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasComment']: Yup.string(),
        ['tasMaxDeductibleAmount']: Yup.string(),
        ['tasTotalDamageAmountDebited']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasOtherSpecify']: Yup.string()
            .when(['tasFormChoice', 'tasIsAbout'], (tasFormChoice, tasIsAbout, schema) => {
                if (choices4.includes(tasFormChoice) && tasIsAbout === I18n.t('otherSpecifyLabel')) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasIsAbout']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasDescriptionCourseDamage']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasNameAddressRentalCompany']: Yup.string()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (choices4.includes(tasFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            })
    }),
    Yup.object().shape({
        ['tasTellAnythingElse']: Yup.string(),
        ['tasAuthorization']: Yup.boolean()
            .when(['tasFormChoice'], (tasFormChoice, schema) => {
                if (!choices3.includes(tasFormChoice) && !choices4.includes(tasFormChoice)) {
                    return Yup.boolean().default(false).oneOf([true], I18n.t('requiredDefaultMessage'));
                }
            }),
        ['tasPowerAttorney']: Yup.boolean().required(I18n.t('requiredDefaultMessage'))
    })
];