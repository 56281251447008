import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationDE from './locales/de/translation.json';
import translationIT from './locales/it/translation.json';

import translationLocalizelyEN from './locales/en/localizely.json';
import translationLocalizelyFR from './locales/fr/localizely.json';
import translationLocalizelyDE from './locales/de/localizely.json';
import translationLocalizelyIT from './locales/it/localizely.json';

const resources = {
    en: {
        translation: {...translationEN, ...translationLocalizelyEN}
    },
    de: {
        translation: {...translationDE, ...translationLocalizelyDE}
    },
    fr: {
        translation: {...translationFR, ...translationLocalizelyFR}
    },
    it: {
        translation: {...translationIT, ...translationLocalizelyIT}
    }
};

i18n
    .use(detector)
    .use(reactI18nextModule)
    .init({
        resources,
        fallbackLng: "de",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;