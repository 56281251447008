import {ErrorMessage, Field, useFormikContext} from "formik";
import InputComponent from "../../../common/Input";
import React from "react";
import RadioComponent from "../../../common/Radio";
import LineComponent from "../../../common/ui/Line";

export default function ChangeTravelAfterDeparture({translation}) {
    const formikProps = useFormikContext();

    const updateCreditCard = (event) => {
        formikProps.setFieldValue('tasCreditCardBooking', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasCreditCardType', '');
        }
    }

    const updateOtherAssurance = (event) => {
        formikProps.setFieldValue('tasOtherAssurance', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasAssuranceName', '');
        }
    }

    const updateReportedOtherAssurance = (event) => {
        formikProps.setFieldValue('tasReportedOtherAssurance', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasNumberReportedOtherAssurance', '');
        }
    }

    return (
        <>
            <div className={'item'}>
                <h5>
                    {translation('plannedTripLabel')}
                </h5>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasHolidayDestination',
                        label: translation('holidayDestinationLabel')
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasBegin',
                        label: translation('beginLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasEnd',
                        label: translation('endLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasPosting',
                        label: translation('postingLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('creditCardBookingLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasCreditCardBooking1',
                        value: translation('noLabel'),
                        name: 'tasCreditCardBooking',
                        label: translation('noLabel')
                    }}
                    change={updateCreditCard}
                />
                <RadioComponent
                    data={{
                        id: 'tasCreditCardBooking2',
                        value: translation('yesLabel'),
                        name: 'tasCreditCardBooking',
                        label: translation('yesLabel')
                    }}
                    change={updateCreditCard}
                />
                <ErrorMessage className="text-danger" name='tasCreditCardBooking' component="div" />
            </div>

            {formikProps.values['tasCreditCardBooking'] && formikProps.values['tasCreditCardBooking'] === translation('yesLabel') &&
                <InputComponent
                    data={{
                        name: 'tasCreditCardType',
                        label: translation('creditCardTypeLabel')
                    }}
                />
            }

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('otherAssuranceLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasOtherAssurance1',
                        value: translation('noLabel'),
                        name: 'tasOtherAssurance',
                        label: translation('noLabel')
                    }}
                    change={updateOtherAssurance}
                />
                <RadioComponent
                    data={{
                        id: 'tasOtherAssurance2',
                        value: translation('yesLabel'),
                        name: 'tasOtherAssurance',
                        label: translation('yesLabel')
                    }}
                    change={updateOtherAssurance}
                />
                <ErrorMessage className="text-danger" name='tasOtherAssurance' component="div" />
            </div>

            {formikProps.values['tasOtherAssurance'] && formikProps.values['tasOtherAssurance'] === translation('yesLabel') &&
                <InputComponent
                    data={{
                        name: 'tasAssuranceName',
                        label: translation('assuranceNameLabel') + '*'
                    }}
                />
            }

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('reportedOtherAssuranceLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasReportedOtherAssurance2',
                        value: translation('yesLabel'),
                        name: 'tasReportedOtherAssurance',
                        label: translation('yesLabel')
                    }}
                    change={updateReportedOtherAssurance}
                />
                <RadioComponent
                    data={{
                        id: 'tasReportedOtherAssurance1',
                        value: translation('noLabel'),
                        name: 'tasReportedOtherAssurance',
                        label: translation('noLabel')
                    }}
                    change={updateReportedOtherAssurance}
                />
                <ErrorMessage className="text-danger" name='tasReportedOtherAssurance' component="div" />
            </div>

            {(formikProps.values['tasReportedOtherAssurance']
                    && formikProps.values['tasReportedOtherAssurance'] === translation('yesLabel')) &&
                <InputComponent
                    data={{
                        name: 'tasNumberReportedOtherAssurance',
                        label: translation('numberReportedOtherAssuranceLabel') + '*'
                    }}
                />
            }

            <LineComponent />

            <div className={'item'}>
                <h5>
                    {translation('damageInformationLabel')}
                </h5>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasSuspensionDate',
                        label: translation('suspensionDateLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasLocationOfDamage',
                        label: translation('locationOfDamageLabel')
                    }}
                />
            </div>

            <div className={'item'}>
                <label  className={'form-label'}>
                    {translation('travelTypeLabel') + '*'}
                    <Field
                        name="tasTravelTypeChoice"
                        as='select'
                        className={'form-select'}>
                        <option value=''/>
                        <option value={translation('travelType1Label')}>
                            {translation('travelType1Label')}
                        </option>
                        <option value={translation('travelType2Label')}>
                            {translation('travelType2Label')}
                        </option>
                        <option value={translation('travelType3Label')}>
                            {translation('travelType3Label')}
                        </option>
                        <option value={translation('travelType4Label')}>
                            {translation('travelType4Label')}
                        </option>
                        <option value={translation('travelType5Label')}>
                            {translation('travelType5Label')}
                        </option>
                        <option value={translation('travelType6Label')}>
                            {translation('travelType6Label')}
                        </option>
                    </Field>
                </label>
                <ErrorMessage className={'text-danger'} name="tasTravelTypeChoice" component='div' />
            </div>

            {formikProps.values['tasTravelTypeChoice'] === translation('travelType6Label') &&
                <div className={'item'}>
                    <InputComponent
                        data={{
                            name: 'tasOtherTravelType',
                            label: translation('otherTravelTypeLabel')
                        }}
                    />
                </div>
            }

            <label className={'form-label'}>
                {translation('whyCouldNotTripPlannedLabel')}
            </label>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('damageReasonLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasReasonDamage1',
                        value: translation('reason1DamageLabel'),
                        name: 'tasReasonDamage',
                        label: translation('reason1DamageLabel') }}
                />
                <RadioComponent
                    data={{
                        id: 'tasReasonDamage2',
                        value: translation('reason2DamageLabel'),
                        name: 'tasReasonDamage',
                        label: translation('reason2DamageLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasReasonDamage3',
                        value: translation('reason3DamageLabel'),
                        name: 'tasReasonDamage',
                        label: translation('reason3DamageLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasReasonDamage4',
                        value: translation('reason4DamageLabel'),
                        name: 'tasReasonDamage',
                        label: translation('reason4DamageLabel') }}
                />
                <ErrorMessage className="text-danger" name='tasReasonDamage' component="div" />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasWhatHappened',
                        label: translation('whatHappenedLabel') + '*',
                        inputMode: "text",
                        component: "textarea"
                    }}
                />
            </div>

            {formikProps.values['tasReasonDamage'] === translation('reason1DamageLabel') &&
                <>
                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('responsibleAccidentLabel') + '*'}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'tasResponsibleAccident1',
                                value: translation('youLabel'),
                                name: 'tasResponsibleAccident',
                                label: translation('youLabel')
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'tasResponsibleAccident2',
                                value: translation('reason4DamageLabel'),
                                name: 'tasResponsibleAccident',
                                label: translation('reason4DamageLabel')
                            }}
                        />
                        <ErrorMessage className="text-danger" name='tasResponsibleAccident' component="div" />
                    </div>

                    {formikProps.values['tasResponsibleAccident'] === translation('reason4DamageLabel') &&
                        <>
                            <div className={'item-names'}>
                                <InputComponent
                                    data={{
                                        name: 'tasResponsibleAccidentFirstname',
                                        label: translation('firstnameLabel')
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'tasResponsibleAccidentSurname',
                                        label: translation('surnameLabel')
                                    }}
                                />
                            </div>

                            <div className={'item-names'}>
                                <InputComponent
                                    data={{
                                        name: 'tasNameLiabilityInsurance',
                                        label: translation('nameLiabilityInsuranceLabel') + '*'
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'tasPolicyNumber',
                                        label: translation('policyNumberLabel') + '*'
                                    }}
                                />
                            </div>
                        </>
                    }

                    {formikProps.values['tasResponsibleAccident'] === translation('youLabel') &&
                        <>
                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation('underInfluenceLabel') + '*'}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'tasUnderInfluence1',
                                        value: translation('yesLabel'),
                                        name: 'tasUnderInfluence',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'tasUnderInfluence2',
                                        value: translation('noLabel'),
                                        name: 'tasUnderInfluence',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='tasUnderInfluence' component="div" />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation('policeReportLabel') + '*'}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'tasPoliceReport1',
                                        value: translation('yesLabel'),
                                        name: 'tasPoliceReport',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'tasPoliceReport2',
                                        value: translation('noLabel'),
                                        name: 'tasPoliceReport',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='tasPoliceReport' component="div" />
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <p>{translation('copyDocumentsLabel')}</p>
                    </div>
                </>
            }
        </>
    )
}