import React from "react";
import {ErrorMessage, Field} from "formik";

export default function InputComponent({data, validate}) {
    return (
        <div className={data.className}>
            <label className={'form-label'}>
                {data.label}
                <Field
                    name={data.name}
                    className={'form-control'}
                    {...(data.autoComplete && {autoComplete: data.autoComplete})}
                    {...(data.type && {type: data.type})}
                    {...(data.component && {component: data.component})}
                    {...(data.inputMode && {inputMode: data.inputMode})}
                    {...(validate && {validate: validate})}
                    {...(data.rows && {rows: data.rows})}
                />
            </label>
            <ErrorMessage className="text-danger" name={data.name} component="div" />
        </div>
    )
}