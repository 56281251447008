import {ErrorMessage, useFormikContext} from "formik";
import InputComponent from "../../../common/Input";
import React from "react";
import RadioComponent from "../../../common/Radio";
import LineComponent from "../../../common/ui/Line";

export default function AssumptionDeductibleRentalVehicles({translation}) {
    const formikProps = useFormikContext();

    const updateTenantFromLease = (event) => {
        formikProps.setFieldValue('tasTenantFromLease', event.currentTarget.value);
        if (event.currentTarget.value === translation('policyholderLabel')) {
            formikProps.setFieldValue('tasGenderOtherPerson', '');
            formikProps.setFieldValue('tasFirstnameOtherPerson', '');
            formikProps.setFieldValue('tasSurnameOtherPerson', '');
            formikProps.setFieldValue('tasBirthdayOtherPerson', '');
            formikProps.setFieldValue('tasAddressOtherPerson', '');
            formikProps.setFieldValue('tasAddressNPAOtherPerson', '');
            formikProps.setFieldValue('tasAddressCityOtherPerson', '');
            formikProps.setFieldValue('tasPhoneOtherPerson', '');
            formikProps.setFieldValue('tasEmailOtherPerson', '');
        }
    }

    const updateCreditCard = (event) => {
        formikProps.setFieldValue('tasCreditCardBooking', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasCreditCardType', '');
        }
    }

    const updateOtherAssurance = (event) => {
        formikProps.setFieldValue('tasOtherAssurance', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasAssuranceName', '');
        }
    }

    const updateReportedOtherAssurance = (event) => {
        formikProps.setFieldValue('tasReportedOtherAssurance', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasNumberReportedOtherAssurance', '');
        }
    }

    const updateBeneficiarySameCoverage = (event) => {
        formikProps.setFieldValue('tasBeneficiarySameCoverage', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasRelationshipDegree', '');
        }
    }

    return (
        <>
            <div className={'item'}>
                <h5>
                    {translation('plannedTripLabel')}
                </h5>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasHolidayDestination',
                        label: translation('holidayDestinationLabel')
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasBegin',
                        label: translation('beginLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasEnd',
                        label: translation('endLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('creditCardBookingLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasCreditCardBooking1',
                        value: translation('noLabel'),
                        name: 'tasCreditCardBooking',
                        label: translation('noLabel')
                    }}
                    change={updateCreditCard}
                />
                <RadioComponent
                    data={{
                        id: 'tasCreditCardBooking2',
                        value: translation('yesLabel'),
                        name: 'tasCreditCardBooking',
                        label: translation('yesLabel')
                    }}
                    change={updateCreditCard}
                />
                <ErrorMessage className="text-danger" name='tasCreditCardBooking' component="div" />
            </div>

            {formikProps.values['tasCreditCardBooking'] && formikProps.values['tasCreditCardBooking'] === translation('yesLabel') &&
                <InputComponent
                    data={{
                        name: 'tasCreditCardType',
                        label: translation('creditCardTypeLabel')
                    }}
                />
            }

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('otherAssuranceLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasOtherAssurance1',
                        value: translation('noLabel'),
                        name: 'tasOtherAssurance',
                        label: translation('noLabel')
                    }}
                    change={updateOtherAssurance}
                />
                <RadioComponent
                    data={{
                        id: 'tasOtherAssurance2',
                        value: translation('yesLabel'),
                        name: 'tasOtherAssurance',
                        label: translation('yesLabel')
                    }}
                    change={updateOtherAssurance}
                />
                <ErrorMessage className="text-danger" name='tasOtherAssurance' component="div" />
            </div>

            {formikProps.values['tasOtherAssurance'] && formikProps.values['tasOtherAssurance'] === translation('yesLabel') &&
                <InputComponent
                    data={{
                        name: 'tasAssuranceName',
                        label: translation('assuranceNameLabel') + '*'
                    }}
                />
            }

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('reportedOtherAssuranceLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasReportedOtherAssurance2',
                        value: translation('yesLabel'),
                        name: 'tasReportedOtherAssurance',
                        label: translation('yesLabel')
                    }}
                    change={updateReportedOtherAssurance}
                />
                <RadioComponent
                    data={{
                        id: 'tasReportedOtherAssurance1',
                        value: translation('noLabel'),
                        name: 'tasReportedOtherAssurance',
                        label: translation('noLabel')
                    }}
                    change={updateReportedOtherAssurance}
                />
                <ErrorMessage className="text-danger" name='tasReportedOtherAssurance' component="div" />
            </div>

            {(formikProps.values['tasReportedOtherAssurance']
                    && formikProps.values['tasReportedOtherAssurance'] === translation('yesLabel')) &&
                <InputComponent
                    data={{
                        name: 'tasNumberReportedOtherAssurance',
                        label: translation('numberReportedOtherAssuranceLabel') + '*'
                    }}
                />
            }

            {(formikProps.values['tasReportedOtherAssurance']
                    && formikProps.values['tasReportedOtherAssurance'] === translation('noLabel')) &&
                <div className={'item'}>
                    <p>
                        <strong>{translation('insuranceCoverMessage')}</strong>
                    </p>
                </div>
            }

            <div className={'item'}>
                <h5>
                    {translation('informationsRentalCompanyLabel')}
                </h5>
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('tenantFromLeaseLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasTenantFromLease1',
                        value: translation('policyholderLabel'),
                        name: 'tasTenantFromLease',
                        label: translation('policyholderLabel')
                    }}
                    change={updateTenantFromLease}
                />
                <RadioComponent
                    data={{
                        id: 'tasTenantFromLease2',
                        value: translation('otherPersonLabel'),
                        name: 'tasTenantFromLease',
                        label: translation('otherPersonLabel')
                    }}
                    change={updateTenantFromLease}
                />
                <ErrorMessage className="text-danger" name='tasTenantFromLease' component="div" />
            </div>

            {(formikProps.values['tasTenantFromLease']
                    && formikProps.values['tasTenantFromLease'] === translation('otherPersonLabel')) &&
                <>
                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('beneficiarySameCoverageLabel') + '*'}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'tasBeneficiarySameCoverage1',
                                value: translation('noLabel'),
                                name: 'tasBeneficiarySameCoverage',
                                label: translation('noLabel')
                            }}
                            change={updateBeneficiarySameCoverage}
                        />
                        <RadioComponent
                            data={{
                                id: 'tasBeneficiarySameCoverage2',
                                value: translation('yesLabel'),
                                name: 'tasBeneficiarySameCoverage',
                                label: translation('yesLabel')
                            }}
                            change={updateBeneficiarySameCoverage}
                        />
                        <ErrorMessage className="text-danger" name='tasBeneficiarySameCoverage' component="div" />
                    </div>

                    <div className={'item'}>
                        <RadioComponent
                            data={{id: 'tasGender1', value: 'male', name: 'tasGenderOtherPerson', label: translation('genderMaleLabel')}}/>
                        <RadioComponent
                            data={{
                                id: 'tasGender2',
                                value: 'female',
                                name: 'tasGenderOtherPerson',
                                label: translation('genderFemaleLabel')
                            }}/>
                        <RadioComponent
                            data={{
                                id: 'tasGender3',
                                value: 'other',
                                name: 'tasGenderOtherPerson',
                                label: translation('genderOtherLabel')
                            }}/>
                        <ErrorMessage className="text-danger" name='tasGenderOtherPerson' component="div"/>
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'tasFirstnameOtherPerson',
                                label: translation('firstnameLabel') + '*',
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'tasSurnameOtherPerson',
                                label: translation('surnameLabel') + '*',
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'tasBirthdayOtherPerson',
                                label: translation('birthdayLabel'),
                                type: "date"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'tasAddressOtherPerson',
                                label: translation('addressLabel') + '*',
                                component: "textarea",
                                rows: "3"
                            }}
                        />
                    </div>

                    <div className={'item-street'}>
                        <InputComponent
                            data={{
                                name: 'tasAddressNPAOtherPerson',
                                label: translation('npaLabel') + '*',
                                inputMode: "numeric"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'tasAddressCityOtherPerson',
                                label: translation('cityLabel') + '*',
                                className: "city"
                            }}
                        />
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'tasPhoneOtherPerson',
                                label: translation('phoneNumberLabel'),
                                type: "tel"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'tasEmailOtherPerson',
                                label: translation('emailLabel'),
                                type: "email"
                            }}
                        />
                    </div>

                    {formikProps.values['tasBeneficiarySameCoverage'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'tasRelationshipDegree',
                                        label: translation('relationshipDegreeLabel') + '*'
                                    }}
                                />
                            </div>
                        </>
                    }
                </>
            }

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasNameAddressRentalCompany',
                        label: translation('nameAddressRentalCompanyLabel') + '*'
                    }}
                />
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('vehicleTypeLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasVehicleType2',
                        value: translation('passengerCarLabel'),
                        name: 'tasVehicleType',
                        label: translation('passengerCarLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasVehicleType3',
                        value: translation('motorbikeLabel'),
                        name: 'tasVehicleType',
                        label: translation('motorbikeLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasVehicleType4',
                        value: translation('minibusLabel'),
                        name: 'tasVehicleType',
                        label: translation('minibusLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasVehicleType5',
                        value: translation('ebikeLabel'),
                        name: 'tasVehicleType',
                        label: translation('ebikeLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasVehicleType6',
                        value: translation('motorVehicleLabel'),
                        name: 'tasVehicleType',
                        label: translation('motorVehicleLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasVehicleType7',
                        value: translation('watercraftLabel'),
                        name: 'tasVehicleType',
                        label: translation('watercraftLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasVehicleType8',
                        value: translation('trailersCarriedLabel'),
                        name: 'tasVehicleType',
                        label: translation('trailersCarriedLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasVehicleType9',
                        value: translation('otherVehicleTypeLabel'),
                        name: 'tasVehicleType',
                        label: translation('otherVehicleTypeLabel')
                    }}
                />
                <ErrorMessage className="text-danger" name='tasVehicleType' component="div" />
            </div>

            {formikProps.values['tasVehicleType'] === translation('otherVehicleTypeLabel') &&
                <div className={'item'}>
                    <InputComponent
                        data={{
                            name: 'tasOtherVehicleType',
                            label: translation('otherVehicleTypeBisLabel') + '*'
                        }}
                    />
                </div>
            }

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasComment',
                        label: translation('commentLabel'),
                        component: "textarea",
                        rows: "3"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasMaxDeductibleAmount',
                        label: translation('maxDeductibleAmountLabel')
                    }}
                />
            </div>

            <LineComponent />

            <div className={'item'}>
                <h5>
                    {translation('damageInformationLabel')}
                </h5>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasDateOfDamage',
                        label: translation('dateOfDamageLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasLocationOfDamage',
                        label: translation('locationOfDamageLabel')
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasTotalDamageAmountDebited',
                        label: translation('totalDamageAmountDebitedLabel') + '*',
                    }}
                />
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('isAboutLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasIsAbout1',
                        value: translation('breakdownLabel'),
                        name: 'tasIsAbout',
                        label: translation('breakdownLabel') }}
                />
                <RadioComponent
                    data={{
                        id: 'tasIsAbout2',
                        value: translation('theftLabel'),
                        name: 'tasIsAbout',
                        label: translation('theftLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasIsAbout3',
                        value: translation('accidentLabel'),
                        name: 'tasIsAbout',
                        label: translation('accidentLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasIsAbout4',
                        value: translation('otherSpecifyLabel'),
                        name: 'tasIsAbout',
                        label: translation('otherSpecifyLabel') }}
                />
                <ErrorMessage className="text-danger" name='tasIsAbout' component="div" />
            </div>

            {(formikProps.values['tasIsAbout']
                    && formikProps.values['tasIsAbout'] === translation('otherSpecifyLabel')) &&
                <InputComponent
                    data={{
                        name: 'tasOtherSpecify',
                        label: translation('otherVehicleTypeBisLabel') + '*'
                    }}
                />
            }

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasDescriptionCourseDamage',
                        label: translation('descriptionCourseDamageLabel') + '*',
                        component: "textarea",
                        rows: "3"
                    }}
                />
            </div>
        </>
    )
}