import React from "react";
import {Field} from "formik";

export default function RadioComponent({data, change}) {
    return (
        <div className={'form-check'}>
            <label className={'form-label'}>
                <Field
                    id={data.id}
                    value={data.value}
                    name={data.name}
                    className={'form-check-input'}
                    type='radio'
                    {...(change && {onChange: change})}
                />
                {data.label}
            </label>
        </div>
    )
}