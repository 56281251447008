export default {
    ['tasFormChoice']: '',
    ['tasInsurer']: '',
    ['tasPoliceNumber']: '',
    ['tasGender']: '',
    ['tasFirstname']: '',
    ['tasSurname']: '',
    ['tasBirthday']: '',
    ['tasAddress']: '',
    ['tasAddressStreet']: '',
    ['tasAddressNPA']: '',
    ['tasAddressCity']: '',
    ['tasLand']: '',
    ['tasPhone']: '',
    ['tasPhoneBis']: '',
    ['tasEmail']: '',
    ['tasAccountHolder']: '',
    ['tasAccountIban']: '',
    ['tasOtherTraveler']: '',
    ['tasOtherTraveler1Firstname']: '',
    ['tasOtherTraveler1Surname']: '',
    ['tasOtherTraveler1SameETIProtection']: '',
    ['tasOtherTraveler1OwnETIProtection']: '',
    ['tasOtherTraveler1TCSNumber']: '',
    ['tasOtherTraveler2Firstname']: '',
    ['tasOtherTraveler2Surname']: '',
    ['tasOtherTraveler2SameETIProtection']: '',
    ['tasOtherTraveler2OwnETIProtection']: '',
    ['tasOtherTraveler2TCSNumber']: '',
    ['tasBegin']: '',
    ['tasEnd']: '',
    ['tasPosting']: '',
    ['tasCreditCardBooking']: '',
    ['tasCreditCardType']: '',
    ['tasAssuranceName']: '',
    ['tasOtherAssurance']: '',
    ['tasDateOfDamage']: '',
    ['tasLocationOfDamage']: '',
    ['tasReasonDamage']: '',
    ['tasWhatHappened']: '',
    ['tasDateOfCancellation']: '',
    ['tasDocument']: [],
    ['tasTellAnythingElse']: '',
    ['tasHolidayDestination']: '',
    ['tasEventLocation']: '',
    ['tasEstimateDamage']: '',
    ['tasAuthorization']: false,
    ['tasOtherTraveler1Address']: '',
    ['tasOtherTraveler1NPA']: '',
    ['tasOtherTraveler1City']: '',
    ['tasOtherTraveler1Birthday']: '',
    ['tasOtherTraveler2Address']: '',
    ['tasOtherTraveler2NPA']: '',
    ['tasOtherTraveler2City']: '',
    ['tasOtherTraveler2Birthday']: '',
    ['tasReportedOtherAssurance']: '',
    ['tasNumberReportedOtherAssurance']: '',
    ['tasSuspensionDate']: '',
    ['tasTravelTypeChoice']: '',
    ['tasOtherTravelType']: '',
    ['tasResponsibleAccident']: '',
    ['tasResponsibleAccidentFirstname']: '',
    ['tasResponsibleAccidentSurname']: '',
    ['tasNameLiabilityInsurance']: '',
    ['tasPolicyNumber']: '',
    ['tasUnderInfluence']: '',
    ['tasPoliceReport']: '',
    ['tasTenantFromLease']: '',
    ['tasGenderOtherPerson']: '',
    ['tasFirstnameOtherPerson']: '',
    ['tasSurnameOtherPerson']: '',
    ['tasBirthdayOtherPerson']: '',
    ['tasAddressOtherPerson']: '',
    ['tasAddressNPAOtherPerson']: '',
    ['tasAddressCityOtherPerson']: '',
    ['tasPhoneOtherPerson']: '',
    ['tasEmailOtherPerson']: '',
    ['tasBeneficiarySameCoverage']: '',
    ['tasRelationshipDegree']: '',
    ['tasInsuredNumberOtherInsurance']: '',
    ['tasBeneficiaryAccountHolder']: '',
    ['tasBeneficiaryAccountIban']: '',
    ['tasVehicleType']: '',
    ['tasOtherVehicleType']: '',
    ['tasComment']: '',
    ['tasMaxDeductibleAmount']: '',
    ['tasTotalDamageAmountDebited']: '',
    ['tasDeductibleCharged']: '',
    ['tasOtherSpecify']: '',
    ['tasIsAbout']: '',
    ['tasDescriptionCourseDamage']: '',
    ['tasPowerAttorney']: false,
    ['tasNameAddressRentalCompany']: ''
}