import InputComponent from "../../../common/Input";
import React from "react";
import RadioComponent from "../../../common/Radio";
import {ErrorMessage, useFormikContext} from "formik";
import LineComponent from "../../../common/ui/Line";

export default function TravelCancellationBeforeDeparture({translation}) {
    const formikProps = useFormikContext();

    const choices3 = [
        translation('choice3Label')
    ];

    const updateCreditCard = (event) => {
        formikProps.setFieldValue('tasCreditCardBooking', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasCreditCardType', '');
        }
    }

    const updateOtherAssurance = (event) => {
        formikProps.setFieldValue('tasOtherAssurance', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasAssuranceName', '');
        }
    }

    function validateRequired(value) {
        let error;
        if (!value) {
            if (formikProps.values['tasFormChoice'] !== ''
                && !choices3.includes(formikProps.values['tasFormChoice'])) {
                error = translation('requiredDefaultMessage');
            }
        }
        return error;
    }

    return (
        <>
            <div className={'item'}>
                <h5>
                    {translation('plannedTripLabel')}
                </h5>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasHolidayDestination',
                        label: translation('holidayDestinationLabel')
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasBegin',
                        label: translation('beginLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasEnd',
                        label: translation('endLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasPosting',
                        label: translation('postingLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('creditCardBookingLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasCreditCardBooking1',
                        value: translation('noLabel'),
                        name: 'tasCreditCardBooking',
                        label: translation('noLabel')
                    }}
                    change={updateCreditCard}
                />
                <RadioComponent
                    data={{
                        id: 'tasCreditCardBooking2',
                        value: translation('yesLabel'),
                        name: 'tasCreditCardBooking',
                        label: translation('yesLabel')
                    }}
                    change={updateCreditCard}
                />
                <ErrorMessage className="text-danger" name='tasCreditCardBooking' component="div" />
            </div>

            {formikProps.values['tasCreditCardBooking'] && formikProps.values['tasCreditCardBooking'] === translation('yesLabel') &&
                <InputComponent
                    data={{
                        name: 'tasCreditCardType',
                        label: translation('creditCardTypeLabel')
                    }}
                />
            }

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('otherAssuranceLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasOtherAssurance1',
                        value: translation('noLabel'),
                        name: 'tasOtherAssurance',
                        label: translation('noLabel')
                    }}
                    change={updateOtherAssurance}
                />
                <RadioComponent
                    data={{
                        id: 'tasOtherAssurance2',
                        value: translation('yesLabel'),
                        name: 'tasOtherAssurance',
                        label: translation('yesLabel')
                    }}
                    change={updateOtherAssurance}
                />
                <ErrorMessage className="text-danger" name='tasOtherAssurance' component="div" />
            </div>

            {formikProps.values['tasOtherAssurance'] && formikProps.values['tasOtherAssurance'] === translation('yesLabel') &&
                <InputComponent
                    data={{
                        name: 'tasAssuranceName',
                        label: translation('assuranceNameLabel') + '*'
                    }}
                />
            }

            <LineComponent />

            <div className={'item'}>
                <h5>
                    {translation('damageInformationLabel')}
                </h5>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasDateOfDamage',
                        label: translation('dateOfDamageLabel') + '*',
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasLocationOfDamage',
                        label: translation('locationOfDamageLabel')
                    }}
                />
            </div>

            <label className={'form-label'}>
                {translation('cantStartTripLabel')}
            </label>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('damageReasonLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasReasonDamage1',
                        value: translation('reason1DamageLabel'),
                        name: 'tasReasonDamage',
                        label: translation('reason1DamageLabel') }}
                />
                <RadioComponent
                    data={{
                        id: 'tasReasonDamage2',
                        value: translation('reason2DamageLabel'),
                        name: 'tasReasonDamage',
                        label: translation('reason2DamageLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasReasonDamage3',
                        value: translation('reason3DamageLabel'),
                        name: 'tasReasonDamage',
                        label: translation('reason3DamageLabel')
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasReasonDamage4',
                        value: translation('reason4DamageLabel'),
                        name: 'tasReasonDamage',
                        label: translation('reason4DamageLabel') }}
                />
                <ErrorMessage className="text-danger" name='tasReasonDamage' component="div" />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasWhatHappened',
                        label: translation('whatHappenedLabel') + '*',
                        inputMode: "text",
                        component: "textarea"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasDateOfCancellation',
                        label: translation('dateOfCancellationLabel') + '*',
                        type: "date"
                    }}
                    validate={validateRequired}
                />
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('estimateDamageLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: 'tasEstimateDamage1',
                        value: "≤ CHF 1'000.-",
                        name: 'tasEstimateDamage',
                        label: "≤ CHF 1'000.-"
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'tasEstimateDamage2',
                        value: "> CHF 1'000.-",
                        name: 'tasEstimateDamage',
                        label: "> CHF 1'000.-"
                    }}
                />
                <ErrorMessage className="text-danger" name='tasEstimateDamage' component="div" />
            </div>
        </>
    )
}