import React, {useCallback, useEffect, useState} from 'react';
import {ErrorMessage, Field, useFormikContext} from 'formik';
import {extensions} from "../../../common/functions";
import {useDropzone} from "react-dropzone";
import InputComponent from "../../../common/Input";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {Trans} from "react-i18next";

export default function TasStep4({translation, i18n}) {
    const [pdfFileName, setPdfFileName] = useState('');
    const [pdfFile, setPdfFile] = useState();

    const formikProps = useFormikContext();

    const otherReasonsDamage = [
        translation('reason1DamageLabel'),
        translation('reason3DamageLabel'),
        translation('reason4DamageLabel')
    ];

    const choices3 = [
        translation('choice3Label')
    ];

    const choices4 = [
        translation('choice4Label')
    ];

    const acceptedFormats = {
        'image/jpg': [],
        'image/jpeg': [],
        'image/gif': [],
        'image/png': [],
        'application/pdf': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    }

    const displayExtensions = () => {
        let authorizedExtensions = translation('authorizedExtensionLabel');
        extensions.forEach((extension, index) => {
            authorizedExtensions += extension + (index !== extensions.length - 1 ? ', ' : '');
        })
        return authorizedExtensions;
    }

    const displayMaxSize = () => {
        return translation('maxSizeLabel') + "5 MB";
    }

    const onDrop = useCallback(acceptedFiles => {
        formikProps.setFieldValue('tasDocument', [...acceptedFiles, ...formikProps.values['tasDocument']]);
    })

    const {fileRejections, getRootProps, getInputProps} = useDropzone({accept: acceptedFormats, onDrop: onDrop, maxSize: 5000000})

    const removeFile = (name) => {
        const validFileIndex = formikProps.values['tasDocument'].findIndex(e => e.name === name)
        formikProps.values['tasDocument'].splice(validFileIndex, 1);
        formikProps.setFieldValue('tasDocument',[...formikProps.values['tasDocument']]);
    }

    const humanFileSize = (size) => {
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    const displayFiles = formikProps.values['tasDocument'].map((file, index) => (
        <div className={'acceptedFile'} key={index}>
            <div className={'acceptedFileName'}>
                <div>{file.name}</div>
                <div><b>{humanFileSize(file.size)}</b></div>
            </div>
            <button
                className={'button'}
                type="button"
                onClick={() => removeFile(file)}>
                {translation('deleteFileLabel')}
            </button>
        </div>
    ))

    const forbiddenFiles = fileRejections.map(({ file, errors }, index) => (
        <div className={'acceptedFile'} key={"error-" + index}>
            <div className={'acceptedFileName forbiddenFile'}>
                <div>{file.name}</div>
                <div><b>{humanFileSize(file.size)}</b></div>
            </div>
            <div className={'forbiddenFile'}>{translation('wrongExtensionAndFileTooBigLabel')}</div>
        </div>
    ));

    const getPdfFile = () => {
        switch (i18n.language) {
            case 'fr':
                return require('../../../../Files/fr/reiseannullierung-arztbericht_fr.pdf');
            case 'de':
                return require('../../../../Files/de/reiseannullierung-arztbericht.pdf');
            case 'it':
                return require('../../../../Files/it/reiseannullierung-arztbericht_it.pdf');
            default:
                return require('../../../../Files/de/reiseannullierung-arztbericht.pdf');
        }
    }

    const getPdfFileName = () => {
        switch (i18n.language) {
            case 'fr':
                return 'reiseannullierung-arztbericht_fr.pdf';
            case 'de':
                return 'reiseannullierung-arztbericht.pdf';
            case 'it':
                return 'reiseannullierung-arztbericht_it.pdf';
            default:
                return 'reiseannullierung-arztbericht.pdf';
        }
    }

    useEffect(() => {
        setPdfFileName(getPdfFileName());
        setPdfFile(getPdfFile());
    });

    return (
        <>
            <div className={'item'}>
                <h4>
                    {translation('step4Label')}
                </h4>
            </div>

            <div className={'item'}>
                {!choices4.includes(formikProps.values['tasFormChoice']) &&
                    <>
                        {formikProps.values['tasReasonDamage'] &&
                            <p className={'form-label'}>
                                {translation('uploadFollowingDocumentsLabel')}
                            </p>
                        }
                    </>
                }
                {choices4.includes(formikProps.values['tasFormChoice']) &&
                    <p className={'form-label'}>
                        {translation('uploadFollowingDocumentsLabel')}
                    </p>
                }
                {choices3.includes(formikProps.values['tasFormChoice']) &&
                    <ul>
                        <li>{translation('bookingConfirmationLabel')}</li>
                        <li>{translation('documentsProveCancellationLabel')}</li>
                        <li>{translation('receiptsAdditionalCostsLabel')}</li>
                        <li>{translation('accidentInsuranceContractsLabel')}</li>
                        <li>{translation('copyInsuranceOrCertificateLabel')}</li>
                    </ul>
                }
                {choices4.includes(formikProps.values['tasFormChoice']) &&
                    <>
                        <ul>
                            <li>{translation('copyRentalAgreementLabel')}</li>
                            <li>{translation('recordHandoverLabel')}</li>
                            <li>{translation('recordReturnLabel')}</li>
                            <li>{translation('originalReportLabel')}</li>
                            <li>{translation('copyFinalStatementLabel')}</li>
                            <li>{translation('proofChargeLabel')}</li>
                        </ul>
                        <p>
                            {translation('additionalDocumentsLabel')}
                        </p>
                    </>

                }
                {(!choices3.includes(formikProps.values['tasFormChoice']) && !choices4.includes(formikProps.values['tasFormChoice'])) &&
                    <>
                        {otherReasonsDamage.includes(formikProps.values['tasReasonDamage']) &&
                            <ul>
                                <li>{translation('bookingConfirmationLabel')}</li>
                                <li>{translation('settlementCancellationCostLabel')}</li>
                                <li>{translation('documentIncidentHasOccured1Label')}</li>
                                <li>{translation('documentInsurancePolicy')}</li>
                            </ul>
                        }
                        {formikProps.values['tasReasonDamage'] === translation('reason2DamageLabel') &&
                            <ul>
                                <li>{translation('bookingConfirmationLabel')}</li>
                                <li>{translation('settlementCancellationCostLabel')}</li>
                                {formikProps.values['tasEstimateDamage'] === "> CHF 1'000.-" &&
                                    <li>{translation('documentIncidentHasOccured2Label')}</li>
                                }
                                {formikProps.values['tasEstimateDamage'] !== "> CHF 1'000.-" &&
                                    <li>{translation('documentIncidentHasOccured1Label')}</li>
                                }
                            </ul>
                        }
                    </>
                }
                {formikProps.values['tasReasonDamage'] &&
                    <p>
                        {translation('additionalDocumentsLabel')}
                    </p>
                }
            </div>

            <div className={'item'}>
                <p>{displayExtensions()}</p>
                <p>{displayMaxSize()}</p>
                <div className={'dropContainer'} {...getRootProps()}>
                    <input name='tasDocument' {...getInputProps()} />
                    <p>{translation('dragndropLabel')}</p>
                </div>
                <aside>
                {formikProps.values['tasDocument'] && formikProps.values['tasDocument'].length > 0 &&
                    <div className={'acceptedFiles'}>
                        {displayFiles}
                    </div>
                }
                    <div className={'acceptedFiles'}>
                        {forbiddenFiles}
                    </div>
                </aside>
                <ErrorMessage className="text-danger" name='tasDocument' component="div" />
            </div>

            <div className={'item'}>
                <h6>
                    {translation('powerAttorneyLabel')}
                </h6>
                <div className={'form-check'}>
                    <label className={'form-label'}>
                        <Field
                            id='tasPowerAttorney'
                            name='tasPowerAttorney'
                            className={'form-check-input'}
                            type='checkbox'
                        />
                        {translation('powerAttorneyText') + '*'}
                    </label>
                    <ErrorMessage className="text-danger" name='tasPowerAttorney' component="div" />
                </div>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasTellAnythingElse',
                        label: translation('tellAnythingElseLabel'),
                        component: "textarea"
                    }}
                />
            </div>

            <div className="item">
                <p>
                    <b>
                        {translation('claimLabelChoice4')}
                    </b>
                </p>
                <p>
                    {translation('claimTextChoice4')}
                </p>
            </div>

            {!choices4.includes(formikProps.values['tasFormChoice']) && !choices3.includes(formikProps.values['tasFormChoice']) &&
                <>
                    {(formikProps.values['tasReasonDamage'] === translation('reason2DamageLabel')
                            && formikProps.values['tasEstimateDamage'] === "> CHF 1'000.-") &&
                        <>
                            <div className={'item'}>
                                <h5>
                                    {translation('medicalFormTitle')}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <p>
                                    {translation('medicalFormSubtitle')}
                                </p>
                            </div>

                            <div className={'item'}>
                                <a href={pdfFile} download={pdfFileName}>
                                    <PictureAsPdfIcon
                                        sx={
                                            {
                                                fontSize: { xxs: 30, xs: 30, sm: 30, md: 30, lg: 30, xl: 30 },
                                                color: 'var(--my-theme)'
                                            }
                                        }
                                    />
                                    {pdfFileName}
                                </a>
                            </div>

                            <div className={'item'}>
                                <p>
                                    {translation('medicalFormText')}
                                </p>
                            </div>

                            <div className={'item'}>
                                <p>
                                    {translation('medicalFormImportantText')}
                                </p>
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h6>
                            {translation('authorizationLabel') + '*'}
                        </h6>
                        <div className={'form-check'}>
                            <label className={'form-label'}>
                                <Field
                                    id='tasAuthorization'
                                    name='tasAuthorization'
                                    className={'form-check-input'}
                                    type='checkbox'
                                />
                                {translation('authorizationText')}
                            </label>
                            <ErrorMessage className="text-danger" name='tasAuthorization' component="div" />
                        </div>
                    </div>

                    <div className="item">
                        <p>
                            {translation('securityWarning')}
                        </p>
                    </div>

                    <div className="item">
                        <h5>
                            {translation('processingDataLabel')}
                        </h5>
                    </div>

                    <div className="item">
                        <p>
                            <Trans>
                                privacyPolicy1
                                <a href={translation('privacyPolicyLink')} target="_blank" rel="noopener noreferrer">
                                    privacyPolicyLinkLabel
                                </a>
                                privacyPolicy2
                            </Trans>
                        </p>
                    </div>
                </>
            }
        </>
    )
}