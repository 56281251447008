import React from 'react';
import {ErrorMessage, useFormikContext} from 'formik';
import InputComponent from "../../../common/Input";
import RadioComponent from "../../../common/Radio";
import LineComponent from "../../../common/ui/Line";

export default function TasStep2({translation}) {
    const formikProps = useFormikContext();

    const choices3 = [
        translation('choice3Label')
    ];

    const choices4 = [
        translation('choice4Label')
    ];

    const updateOtherTraveler = (event) => {
        formikProps.setFieldValue('tasOtherTraveler', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasOtherTraveler1Firstname', '');
            formikProps.setFieldValue('tasOtherTraveler1Surname', '');

            formikProps.setFieldValue('tasOtherTraveler1Address', '');

            formikProps.setFieldValue('tasOtherTraveler1NPA', '');
            formikProps.setFieldValue('tasOtherTraveler1City', '');

            formikProps.setFieldValue('tasOtherTraveler1Birthday', '');

            formikProps.setFieldValue('tasOtherTraveler1SameETIProtection', '');
            formikProps.setFieldValue('tasOtherTraveler1OwnETIProtection', '');
            formikProps.setFieldValue('tasOtherTraveler1TCSNumber', '');

            formikProps.setFieldValue('tasOtherTraveler2Firstname', '');
            formikProps.setFieldValue('tasOtherTraveler2Surname', '');

            formikProps.setFieldValue('tasOtherTraveler2Address', '');
            formikProps.setFieldValue('tasOtherTraveler2NPA', '');
            formikProps.setFieldValue('tasOtherTraveler2City', '');

            formikProps.setFieldValue('tasOtherTraveler2Birthday', '');

            formikProps.setFieldValue('tasOtherTraveler2SameETIProtection', '');
            formikProps.setFieldValue('tasOtherTraveler2OwnETIProtection', '');
            formikProps.setFieldValue('tasOtherTraveler2TCSNumber', '');
        }
    }

    const updateOtherTravelerBis = (event) => {
        formikProps.setFieldValue('tasOtherTraveler2', event.currentTarget.value);
        if (event.currentTarget.value === translation('noLabel')) {
            formikProps.setFieldValue('tasOtherTraveler2Firstname', '');
            formikProps.setFieldValue('tasOtherTraveler2Surname', '');

            formikProps.setFieldValue('tasOtherTraveler2Address', '');
            formikProps.setFieldValue('tasOtherTraveler2NPA', '');
            formikProps.setFieldValue('tasOtherTraveler2City', '');

            formikProps.setFieldValue('tasOtherTraveler2Birthday', '');

            formikProps.setFieldValue('tasOtherTraveler2SameETIProtection', '');
            formikProps.setFieldValue('tasOtherTraveler2OwnETIProtection', '');
            formikProps.setFieldValue('tasOtherTraveler2TCSNumber', '');
        }
    }

    const updateSameETITraveler = (protection, tcsNumber) => (event) => {
        formikProps.setFieldValue(event.currentTarget.name, event.currentTarget.value);
        if (event.currentTarget.value === translation('yesLabel')) {
            formikProps.setFieldValue(protection, '');
            formikProps.setFieldValue(tcsNumber, '');
        }
    }

    function validateRequired(value) {
        let error;
        if (!value) {
            if (formikProps.values['tasFormChoice'] !== ''
                && choices3.includes(formikProps.values['tasFormChoice'])) {
                error = translation('requiredDefaultMessage');
            }
        }
        return error;
    }

    return (
        <>
            <div className={'item'}>
                <h4>
                    {translation('step2Label')}
                </h4>
            </div>

            <LineComponent/>

            <div className={'item'}>
                <InputComponent data={{name: 'tasInsurer', label: translation('insurerLabel') + '*'}}/>
            </div>

            <div className={'item'}>
                <InputComponent data={{name: 'tasPoliceNumber', label: translation('policeNumberLabel') + '*'}}/>
            </div>

            <div className={'item'}>
                <RadioComponent
                    data={{
                        id: 'tasGender1',
                        value: 'male',
                        name: 'tasGender',
                        label: translation('genderMaleLabel')
                }}/>
                <RadioComponent
                    data={{
                        id: 'tasGender2',
                        value: 'female',
                        name: 'tasGender',
                        label: translation('genderFemaleLabel')
                    }}/>
                <RadioComponent
                    data={{
                        id: 'tasGender3',
                        value: 'other',
                        name: 'tasGender',
                        label: translation('genderOtherLabel')
                    }}/>
                <ErrorMessage className="text-danger" name='tasGender' component="div"/>
            </div>

            <div className={'item-names'}>
                <InputComponent
                    data={{
                        name: 'tasFirstname',
                        label: translation('firstnameLabel') + '*',
                        autoComplete: "given-name"
                    }}
                />
                <InputComponent
                    data={{
                        name: 'tasSurname',
                        label: translation('surnameLabel') + '*',
                        autoComplete: "family-name"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasBirthday',
                        label: translation('birthdayLabel'),
                        autoComplete: "bday",
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasAddress',
                        label: translation('addressLabel') + '*',
                        autoComplete: "street-address",
                        component: "textarea",
                        rows: "3"
                    }}
                />
            </div>

            <div className={'item-street'}>
                <InputComponent
                    data={{
                        name: 'tasAddressNPA',
                        label: translation('npaLabel') + '*',
                        autoComplete: "postal-code",
                        inputMode: "numeric"
                    }}
                />
                <InputComponent
                    data={{
                        name: 'tasAddressCity',
                        label: translation('cityLabel') + '*',
                        autoComplete: "address-level2",
                        className: "city"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasLand',
                        label: translation('landLabel') + '*',
                        autoComplete: "country-name"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasPhone',
                        label: translation('phoneNumberLabel') + '*',
                        autoComplete: "tel",
                        type: "tel"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasPhoneBis',
                        label: translation('phoneNumberBisLabel'),
                        type: "tel"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasEmail',
                        label: translation('emailLabel') + '*',
                        autoComplete: "email",
                        type: "email"
                    }}
                />
            </div>

            <LineComponent/>

            <div className={'item'}>
                <h5>
                    {translation('paymentDetailsLabel')}
                </h5>
                <p>
                    {translation('whichBankAccountLabel')}
                </p>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasAccountHolder',
                        label: translation('accountHolderLabel')
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'tasAccountIban',
                        label: translation('accountIbanLabel')
                    }}
                />
            </div>

            {!choices4.includes(formikProps.values['tasFormChoice']) &&
                <>
                    <LineComponent/>

                    <div className={'item'}>
                        <h5>
                            {translation('travelerInfoLabel')}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('otherTravelerHaveToCancelLabel')}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'tasOtherTraveler1',
                                value: translation('noLabel'),
                                name: 'tasOtherTraveler',
                                label: translation('noLabel')
                            }}
                            change={updateOtherTraveler}
                        />
                        <RadioComponent
                            data={{
                                id: 'tasOtherTraveler2',
                                value: translation('yesLabel'),
                                name: 'tasOtherTraveler',
                                label: translation('yesLabel')
                            }}
                            change={updateOtherTraveler}
                        />
                        <ErrorMessage className="text-danger" name='tasOtherTraveler' component="div"/>
                    </div>
                </>
            }

            {formikProps.values['tasOtherTraveler'] && formikProps.values['tasOtherTraveler'] === translation('yesLabel') &&
                <>
                    <div className={'item'}>
                        <h5>
                            {translation('traveler1InfoLabel')}
                        </h5>
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'tasOtherTraveler1Firstname',
                                label: translation('firstnameLabel') + '*'
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'tasOtherTraveler1Surname',
                                label: translation('surnameLabel') + '*'
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('travelerSameETIProtection') + '*'}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'tasOtherTraveler1SameETIProtection1',
                                value: translation('noLabel'),
                                name: 'tasOtherTraveler1SameETIProtection',
                                label: translation('noLabel')
                            }}
                            change={updateSameETITraveler('tasOtherTraveler1OwnETIProtection', 'tasOtherTraveler1TCSNumber')}
                        />
                        <RadioComponent
                            data={{
                                id: 'tasOtherTraveler1SameETIProtection2',
                                value: translation('yesLabel'),
                                name: 'tasOtherTraveler1SameETIProtection',
                                label: translation('yesLabel')
                            }}
                            change={updateSameETITraveler('tasOtherTraveler1OwnETIProtection', 'tasOtherTraveler1TCSNumber')}
                        />
                        <ErrorMessage className="text-danger" name='tasOtherTraveler1SameETIProtection'
                                      component="div"/>
                    </div>

                    {formikProps.values['tasOtherTraveler1SameETIProtection'] && formikProps.values['tasOtherTraveler1SameETIProtection'] === translation('noLabel') &&
                        <>
                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation('travelerOwnETIProtection')}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'tasOtherTraveler1OwnETIProtection1',
                                        value: translation('noLabel'),
                                        name: 'tasOtherTraveler1OwnETIProtection',
                                        label: translation('noLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'tasOtherTraveler1OwnETIProtection2',
                                        value: translation('yesLabel'),
                                        name: 'tasOtherTraveler1OwnETIProtection',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='tasOtherTraveler1OwnETIProtection'
                                              component="div"/>
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'tasOtherTraveler1TCSNumber',
                                        label: translation('travelerTCSNumberLabel')
                                    }}
                                />
                            </div>
                        </>
                    }

                    {formikProps.values['tasOtherTraveler1Firstname']
                        && formikProps.values['tasOtherTraveler1Firstname'] !== ""
                        && formikProps.values['tasOtherTraveler1Surname']
                        && formikProps.values['tasOtherTraveler1Surname'] !== ""
                        && formikProps.values['tasOtherTraveler1SameETIProtection']
                        && formikProps.values['tasOtherTraveler1SameETIProtection'] !== "" &&
                        <>
                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation('otherTravelerHaveToCancelLabel')}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'tasOtherTraveler3',
                                        value: translation('noLabel'),
                                        name: 'tasOtherTraveler2',
                                        label: translation('noLabel')
                                    }}
                                    change={updateOtherTravelerBis}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'tasOtherTraveler4',
                                        value: translation('yesLabel'),
                                        name: 'tasOtherTraveler2',
                                        label: translation('yesLabel')
                                    }}
                                    change={updateOtherTravelerBis}
                                />
                                <ErrorMessage className="text-danger" name='tasOtherTraveler2' component="div"/>
                            </div>

                            {formikProps.values['tasOtherTraveler2'] && formikProps.values['tasOtherTraveler2'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <h5>
                                            {translation('traveler2InfoLabel')}
                                        </h5>
                                    </div>

                                    <div className={'item-names'}>
                                        <InputComponent
                                            data={{
                                                name: 'tasOtherTraveler2Firstname',
                                                label: translation('firstnameLabel')
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'tasOtherTraveler2Surname',
                                                label: translation('surnameLabel')
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation('travelerSameETIProtection')}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'tasOtherTraveler2SameETIProtection1',
                                                value: translation('noLabel'),
                                                name: 'tasOtherTraveler2SameETIProtection',
                                                label: translation('noLabel')
                                            }}
                                            change={updateSameETITraveler('tasOtherTraveler2OwnETIProtection', 'tasOtherTraveler2TCSNumber')}
                                        />
                                        <RadioComponent
                                            data={{
                                                id: 'tasOtherTraveler2SameETIProtection2',
                                                value: translation('yesLabel'),
                                                name: 'tasOtherTraveler2SameETIProtection',
                                                label: translation('yesLabel')
                                            }}
                                            change={updateSameETITraveler('tasOtherTraveler2OwnETIProtection', 'tasOtherTraveler2TCSNumber')}
                                        />
                                        <ErrorMessage className="text-danger" name='tasOtherTraveler2SameETIProtection'
                                                      component="div"/>
                                    </div>

                                    {formikProps.values['tasOtherTraveler2SameETIProtection'] && formikProps.values['tasOtherTraveler2SameETIProtection'] === translation('noLabel') &&
                                        <>
                                            <div className={'item'}>
                                                <label className={'form-label'}>
                                                    {translation('travelerOwnETIProtection')}
                                                </label>
                                                <RadioComponent
                                                    data={{
                                                        id: 'tasOtherTraveler2OwnETIProtection1',
                                                        value: translation('noLabel'),
                                                        name: 'tasOtherTraveler2OwnETIProtection',
                                                        label: translation('noLabel')
                                                    }}
                                                />
                                                <RadioComponent
                                                    data={{
                                                        id: 'tasOtherTraveler2OwnETIProtection2',
                                                        value: translation('yesLabel'),
                                                        name: 'tasOtherTraveler2OwnETIProtection',
                                                        label: translation('yesLabel')
                                                    }}
                                                />
                                                <ErrorMessage className="text-danger" name='tasOtherTraveler2OwnETIProtection'
                                                              component="div"/>
                                            </div>

                                            <div className={'item'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'tasOtherTraveler2TCSNumber',
                                                        label: translation('travelerTCSNumberLabel')
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}